<template>
    <router-view></router-view>
    <HeadNav></HeadNav>
    <div class="bg j-f-c-j-c" :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">
        <div style="width: 1344px;" class="h-right-align-center">
            <div class="cards j-f-c-c">
                <div class="j-f-r-c" style="margin-top:30px;margin-bottom: 30px;">
                    <div @click="CardItmesChange(item.name)" style="width:150px;" class="j-f-c-j-c"
                        v-for="(item, index) in CardItmesList" :key="index">
                        <button :class="item.value ? 'loginOptions-Select' : 'loginOptions'">{{ item.name }}</button>
                        <div v-if="item.value" class="loginOptions-Select-hx"></div>
                    </div>
                </div>

                <div v-if="CardItmesKey == '登录'">
                    <div class="j-f-r-c inputs">
                        <img class="input-icon" src="../img/icon-login-phone.png">
                        <input type="text" placeholder="请输入手机号" @input="InputChange({ key: 'account', event: $event })"
                            @keyup.enter="Login">
                    </div>
                    <div class="j-f-r-c inputs" style="margin-bottom: 8px;">
                        <img class="input-icon" src="../img/icon-login-password.png">
                        <input type="password" placeholder="请输入登录密码"
                            @input="InputChange({ key: 'Password', event: $event })" @keyup.enter="Login">
                    </div>
                    <div @click="to_new_page({path:'/waiter'})" class="j-f-e"
                        style="font-size: 12px;color: #4975e9;cursor: pointer;margin-bottom: 15px;">忘记密码？
                    </div>

                    <div class="j-s-b">
                        <div class="j-f-r-c inputs" style="width: 200px;">
                            <img class="input-icon" src="../img/icon-login-code.png">
                            <input type="text" placeholder="请输入图形验证码"
                                @input="InputChange({ key: 'verification_code', event: $event })" @keyup.enter="Login">
                        </div>
                        <span class="inputs-getcode" v-html="verification_code_svg" @click="get_verification_code"
                            ref="captcha">
                        </span>

                    </div>


                    <div class="buttons j-f-c-j-c" @click="Login" style="margin-top: 75px;">
                        <LoadAnimation v-if="LoginValue"></LoadAnimation>
                        <button v-if="!LoginValue" style="color: #fff;font-weight: bold;">登录</button>
                    </div>

                </div>

                <div v-if="CardItmesKey == '注册'">
                    <div class="j-f-r-c inputs">
                        <img class="input-icon" src="../img/icon-login-phone.png">
                        <input type="text" placeholder="请输入手机号" @input="InputChange({ key: 'account', event: $event })">
                    </div>
                    <div class="j-f-r-c inputs">
                        <img class="input-icon" src="../img/icon-login-password.png">
                        <input type="password" placeholder="请设置登录密码"
                            @input="InputChange({ key: 'Password', event: $event })">
                    </div>
                    <div class="j-f-r-c inputs">
                        <img class="input-icon" src="../img/icon-login-password.png">
                        <input type="password" placeholder="请再次输入登录密码"
                            @input="InputChange({ key: 'Password2', event: $event })">
                    </div>
                    <div class="j-s-b">
                        <div class="j-f-r-c inputs" style="width: 200px;">
                            <img class="input-icon" src="../img/icon-login-code.png">
                            <input type="text" placeholder="请输入图形验证码"
                                @input="InputChange({ key: 'verification_code', event: $event })">
                        </div>
                        <span class="inputs-getcode" v-html="verification_code_svg" @click="get_verification_code"
                            ref="captcha">
                        </span>

                    </div>

                    <div class="buttons j-f-c-j-c" @click="Register">
                        <LoadAnimation v-if="RegisterValue"></LoadAnimation>
                        <button v-if="!RegisterValue" style="color: #fff;font-weight: bold;">注册</button>
                    </div>


                </div>

            </div>
        </div>
    </div>

    <FootNav></FootNav>
</template>
<script>
    import LoadAnimation from './dist/LoadAnimation.vue'
    import HeadNav from './dist/HeadNav.vue'
    import FootNav from './dist/FootNav.vue'
    import { SetCookie, GetCookie } from '../js/cookies.js' //注意路径
    import { Post, Get } from '../js/axios.js'
    import { ElMessage } from 'element-plus'

    export default {
        data () {
            return {
                browserWidth: '',
                CardItmesList: [{ name: '登录', value: true }, { name: '注册' }],
                CardItmesKey: '登录',
                SendEmailCode_CountDown: false,
                SendButtonText: '获取验证码',
                PasswordTyep: 'password',
                LoginValue: false,
                RegisterValue: false,
                verification_code_id: '',
                verification_code_svg: ''
            }
        },
        created () {
            this.browserWidth = window.innerWidth
            this.get_verification_code();
        },
        components: {
            HeadNav,
            FootNav,
            LoadAnimation
        },
        methods: {

            InputChange: function (e) {
                this[e.key] = e.event.target.value
            },

            CardItmesChange: function (e) {
                var items = this.CardItmesList
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name == e) {
                        items[i].value = true
                        this.CardItmesKey = e
                    } else {
                        items[i].value = false
                    }
                }
            },

            async get_verification_code () {
                var res = await Get({
                    url: "/api/get_verification_code",
                    this: this,
                });
                if (res.code == 200) {
                    this.verification_code_svg = res.img
                    this.verification_code_id = res.verification_code_id
                } else {
                    alert(res.msg);
                }
            },

            async Register () {
                // alert("注册入口关闭，请从推荐路口注册！");
                if (this.RegisterValue) {
                    return
                }
                this.RegisterValue = true
                var account = this.account
                if (account == "") {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "手机号格式不正确，请重新输入",
                        type: 'warning',
                    })
                    return
                }
                const reg = /^1[3-9]\d{9}$/
                //调用正则验证test()函数
                if (!reg.test(account)) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "手机号格式不正确，请重新输入",
                        type: 'warning',
                    })
                    return
                }

                if (!this.Password || !this.Password.trim().length) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "请设置登录密码",
                        type: 'warning',
                    })
                    return
                }

                if (!this.Password2 || !this.Password2.trim().length) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "请再次输入登录密码",
                        type: 'warning',
                    })
                    return
                }

                if (this.Password !== this.Password2) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "两次密码输入不正确请重新输入",
                        type: 'warning',
                    })
                    return
                }

                if (this.Password.trim().length < 6) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "请设置6位以上密码",
                        type: 'warning',
                    })
                    return
                }

                if (!this.verification_code || !this.verification_code.trim().length) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "请输入图形验证码",
                        type: 'warning',
                    })
                    return
                }

                if (this.verification_code.trim().length !== 4) {
                    this.RegisterValue = false
                    ElMessage({
                        showClose: true,
                        message: "验证码错误",
                        type: 'warning',
                    })
                    return
                }

                var i = GetCookie('i')

                var res = await Post({
                    url: "/api/register",
                    body: {
                        account: this.account,
                        password: this.Password,
                        verification_code: this.verification_code,
                        verification_code_id: this.verification_code_id,
                        i
                    },
                    this: this,
                });
                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                    if (res.data) {
                        SetCookie(res.data)
                        this.$router.go(-1)
                    }
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                    if (res.msg == '验证码失效，请刷新验证码' || res.msg == '验证码错误') {
                        this.get_verification_code()
                    }
                }


                this.RegisterValue = false

            },

            async Login () {


                if (this.LoginValue) {
                    return
                }
                this.LoginValue = true
                var account = this.account
                if (account == "") {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "手机号格式不正确，请重新输入",
                        type: 'warning',
                    })
                    return
                }
                const reg = /^1[3-9]\d{9}$/
                //调用正则验证test()函数
                if (!reg.test(account)) {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "手机号格式不正确，请重新输入",
                        type: 'warning',
                    })
                    return
                }

                if (!this.Password || !this.Password.trim().length) {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "请输入密码",
                        type: 'warning',
                    })
                    return
                }

                if (this.Password.trim().length < 6) {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "请设置6位以上密码",
                        type: 'warning',
                    })
                    return
                }

                if (!this.verification_code || !this.verification_code.trim().length) {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "请输入图形验证码",
                        type: 'warning',
                    })
                    return
                }

                if (this.verification_code.trim().length !== 4) {
                    this.LoginValue = false
                    ElMessage({
                        showClose: true,
                        message: "验证码错误",
                        type: 'warning',
                    })
                    return
                }

                var res = await Post({
                    url: "/api/login",
                    body: {
                        account: this.account,
                        password: this.Password,
                        verification_code: this.verification_code,
                        verification_code_id: this.verification_code_id,
                    },
                    this: this,
                });
                if (res.code == 200) {
                    SetCookie(res.data)
                    this.$router.go(-1)
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                    this.get_verification_code()
                }
                this.LoginValue = false


            },

            to_page (e) {
                this.$router.push({ path: e.path })
            },

            to_new_page (e) {
                this.$router.push({ path: e.path })
                // window.open(`${e.path}`, '_blank');
            },

            ack: function () {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped>
    .inputs-getcode {
        width: 100px;
        font-size: 14px;
        color: #606266;
        height: 45px;
        border: 1px #b4bccc solid;
        margin-bottom: 30px;
        border-radius: 5px;
        cursor: pointer;
    }



    .buttons {
        width: 320px;
        height: 45px;
        background-color: #4975e9;
        border-radius: 5px;
        cursor: pointer;
    }

    .buttons:hover {
        background-color: #7998ee;
    }

    .inputs {
        width: 320px;
        height: 45px;
        border: 1px #b4bccc solid;
        margin-bottom: 30px;
        border-radius: 5px;
    }

    input {
        width: 75%;
        height: 45px;
        font-size: 14px;
        color: #606266;
    }

    .input-icon {
        width: 19px;
        height: 19px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .bg {
        height: 739px;
        background: url(../img/loginbg.png) no-repeat 50%;
        overflow: hidden;
    }

    .cards {
        width: 440px;
        min-height: 500px;
        background: #fff;
        border-radius: 8px;
    }

    .loginOptions {
        height: 40px;
        font-size: 20px;
        color: #333;
        font-weight: 600;
        border: none;
        cursor: pointer;
    }

    .loginOptions:hover {
        color: #666;
    }

    .loginOptions-Select {
        height: 40px;
        font-size: 20px;
        color: #4975e9;
        font-weight: 600;
        border: none;
        cursor: pointer;
    }

    .loginOptions-Select-hx {
        width: 50px;
        height: 5px;
        border-radius: 999px;
        background-color: #4975e9;
    }
</style>