<template>

    <div @click="open_tixian_windows" class="buttons j-f-c-j-c">
        <button style="color: #fff;font-weight: bold;">提现</button>
    </div>

    <div v-if="tixian_windows_value" @click="on_tixian_windows" class="Mask j-f-c-j-c">
        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;padding: 30px;">
            <div class="j-f-c-j-c">

                <div class="j-f-r-c" style="margin-bottom: 20px;width: 100%;">
                    <div style="width: 4px;height: 14px;background-color: #4975e9;margin-right:14px ;" />
                    <div style="color: #1c1f21;font-weight: 700;font-size: 16px;">佣金提现</div>
                </div>
                <div class="tixian_bg">
                    <div class="j-f-c-j-c" style="width: 160px;height: 100%;color: #ffffff;">
                        <div style="font-weight: 700;font-size: 28px;line-height: 24px;">
                            {{referrer_money}}</div>
                        <div style="font-size: 14px;opacity: 0.9;margin-top: 16px;">推广佣金(元)</div>
                    </div>
                </div>
                <div>

                    <div class="j-f-r-c" style="margin-bottom: 30px;">
                        <div style="color: #666;font-size: 14px;">输入提现金额：</div>
                        <div class="j-f-r-c inputs" style="margin-bottom: 0;">
                            <div class="input-icon" style="color: #999;">￥</div>
                            <input type="text" placeholder="请输入提现金额" :value="tixian_price"
                                @input="InputChange({ key: 'tixian_price', event: $event })">
                        </div>
                        <div style="color:#4975e9;font-size: 14px;margin-left: 15px;cursor: pointer;"
                            @click="all_tixian">全部提现</div>
                    </div>

                    <div class="j-f-r-c" style="font-size: 16px;color: #666;margin-bottom: 30px;">
                        <div style="margin-right: 15px;">支付宝姓名</div>
                        <div style="width: 300px;color: #222;">{{alipay_name}}</div>
                    </div>
                    <div class="j-s-b" style="margin-bottom: 50px;">
                        <div class="j-f-r-c" style="font-size: 16px;color: #666;">
                            <div style="margin-right: 15px;">支付宝账号</div>
                            <div style="width: 300px;color: #222;">{{alipay_account}}</div>
                        </div>
                        <div @click="on_if_password" style="color: #4975e9;font-size: 14px;cursor: pointer;">
                            {{alipay_name?'修改支付宝':'绑定支付宝'}}</div>
                    </div>
                </div>
                <div class="buttons j-f-c-j-c" @click="tixian">
                    <LoadAnimation v-if="tixian_value"></LoadAnimation>
                    <button v-if="!tixian_value" style="color: #fff;font-weight: bold;">提现</button>
                </div>
            </div>

            <img class="items" @click="on_tixian_windows"
                style="width: 25px;height: 25px;position: absolute;top: 0px;right: 0px;padding: 20px;cursor: pointer;"
                src="../../img/icon-close666.png">
        </div>
    </div>

    <div v-if="if_password_windows_value" class="Mask j-f-c-j-c">

        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;padding: 50px;">
            <div class="j-f-c-j-c">
                <div style="font-size: 18px;font-weight: bold;color: #666;margin-bottom: 30px;">账号验证
                </div>
                <div class="j-f-r-c inputs" style="background-color: #f5f7fa;color: #c0c4cc;cursor: not-allowed;">
                    <img class="input-icon" src="../../img/icon-phone-666.png">
                    <input style="color: #c0c4cc;cursor: not-allowed;" type="text" placeholder="请输入账号" :value="account"
                        disabled="true">
                </div>
                <div class="j-f-r-c inputs">
                    <img class="input-icon" src="../../img/icon-login-password.png">
                    <input type="password" placeholder="请输入密码" @input="InputChange({ key: 'password', event: $event })">
                </div>

                <div class="buttons j-f-c-j-c" @click="if_password">
                    <LoadAnimation v-if="if_password_value"></LoadAnimation>
                    <button v-if="!if_password_value" style="color: #fff;font-weight: bold;">确定</button>
                </div>
            </div>

            <img class="items" @click="on_if_password"
                style="width: 25px;height: 25px;position: absolute;top: 0px;right: 0px;padding: 20px;cursor: pointer;"
                src="../../img/icon-close666.png">
        </div>
    </div>

    <div v-if="bind_alipay_windows_value" class="Mask j-f-c-j-c">

        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;padding: 50px;">
            <div class="j-f-c-j-c">
                <div style="font-size: 18px;font-weight: bold;color: #666;margin-bottom: 30px;">绑定支付宝账号
                </div>
                <div class="j-f-r-c" style="margin-bottom: 20px;">
                    <div style="margin-right: 10px;color: #606266;font-size: 15px;">支付宝姓名</div>
                    <div class="j-f-r-c inputs" style="margin-bottom: 0px;">
                        <input style="margin-left: 20px;margin-right: 20px;" type="text" placeholder="请输入支付宝姓名"
                            :value="alipay_name2" @input="InputChange({ key: 'alipay_name2', event: $event })">
                    </div>
                </div>
                <div class="j-f-r-c" style="margin-bottom: 40px;">
                    <div style="margin-right: 10px;color: #606266;font-size: 15px;">支付宝账号</div>
                    <div class="j-f-r-c inputs" style="margin-bottom: 0px;">
                        <input style="margin-left: 20px;margin-right: 20px;" type="text" placeholder="请输入支付宝账号"
                            :value="alipay_account2" @input="InputChange({ key: 'alipay_account2', event: $event })">
                    </div>
                </div>

                <div class="buttons j-f-c-j-c" @click="bind_alipay">
                    <LoadAnimation v-if="bind_alipay_value"></LoadAnimation>
                    <button v-if="!bind_alipay_value" style="color: #fff;font-weight: bold;">绑定</button>
                </div>
            </div>

            <img class="items" @click="on_bind_alipay_windows"
                style="width: 25px;height: 25px;position: absolute;top: 0px;right: 0px;padding: 20px;cursor: pointer;"
                src="../../img/icon-close666.png">
        </div>
    </div>

</template>

<script>

    import { Post, Get } from '../../js/axios.js'
    import { GetCookie } from '../../js/cookies.js' //注意路径
    import { ElMessage } from 'element-plus'
    import LoadAnimation from './LoadAnimation.vue'

    export default {
        name: 'TiXian_button',
        data () {
            return {
                tixian_info: '',
                tixian_windows_value: false,
                tixian_price: '',
                if_password_windows_value: false,
                if_password_value: false,
                bind_alipay_windows_value: false,
                password: '',
                alipay_account2: '',
                alipay_name2: '',
                alipay_account: '',
                alipay_name: '',
                referrer_money: '',
                tixian_value: false
            }
        },
        components: {
            LoadAnimation
        },
        created () {

        },
        methods: {
            async bind_alipay () {
                if (this.bind_alipay_value) {
                    return
                }
                this.bind_alipay_value = true

                if (!this.alipay_name2 || !this.alipay_name2.trim().length) {
                    this.bind_alipay_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入支付宝姓名",
                        type: 'warning',
                    })
                    return
                }

                if (!this.alipay_account2 || !this.alipay_account2.trim().length) {
                    this.bind_alipay_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入支付宝账号",
                        type: 'warning',
                    })
                    return
                }

                var res = await Post({
                    url: '/api/bind_alipay',
                    body: {
                        password: this.password,
                        alipay_name: this.alipay_name2,
                        alipay_account: this.alipay_account2
                    }
                })
                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                    this.alipay_name = this.alipay_name2
                    this.alipay_account = this.alipay_account2
                    this.bind_alipay_windows_value = false
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                }
                this.bind_alipay_value = false

            },

            async if_password () {
                if (this.if_password_value) {
                    return
                }
                this.if_password_value = true

                if (!this.password || !this.password.trim().length) {
                    this.if_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入密码",
                        type: 'warning',
                    })
                    return
                }

                if (this.password.trim().length < 6) {
                    this.if_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "密码错误",
                        type: 'warning',
                    })
                    return
                }

                var res = await Post({
                    url: '/api/if_password',
                    body: {
                        password: this.password
                    }
                })
                if (res.code == 200) {
                    this.alipay_name2 = this.alipay_name
                    this.alipay_account2 = this.alipay_account
                    this.if_password_windows_value = false
                    this.bind_alipay_windows_value = true
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                }
                this.if_password_value = false
            },

            on_bind_alipay_windows () {
                this.bind_alipay_windows_value = !this.bind_alipay_windows_value
            },

            on_if_password () {
                var account = GetCookie('account')
                if (account) {
                    this.account = account
                }
                this.password = ''
                this.if_password_windows_value = !this.if_password_windows_value
            },

            all_tixian () {
                this.tixian_price = this.referrer_money
            },
            open_tixian_windows () {
                this.tixian_windows_value = true
                this.get_tixian_info()
            },

            on_tixian_windows () {

                this.tixian_windows_value = !this.tixian_windows_value
                this.tixian_price = ''
            },

            InputChange: function (e) {
                this[e.key] = e.event.target.value
            },

            to_page (e) {
                this.$router.push({ path: e.path })
            },

            async get_tixian_info () {
                var res = await Get({
                    url: '/api/get_tixian_info',
                })
                if (res.code == 200) {
                    this.alipay_account = res.data.alipay_account
                    this.alipay_name = res.data.alipay_name
                    this.referrer_money = res.data.referrer_money
                }
            },

            async tixian () {


                if (this.tixian_value) {
                    return
                }
                this.tixian_value = true

                if (!this.tixian_price || !this.tixian_price.trim().length || !Number(this.tixian_price)) {
                    this.tixian_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入提现金额",
                        type: 'warning',
                    })
                    return
                }

                if (Number(this.tixian_price) > Number(this.referrer_money)) {
                    this.tixian_value = false
                    ElMessage({
                        showClose: true,
                        message: "提现金额不能大于推广佣金",
                        type: 'warning',
                    })
                    return
                }

                if (!this.alipay_account || !this.alipay_account.trim().length) {
                    this.tixian_value = false
                    ElMessage({
                        showClose: true,
                        message: "请绑定支付宝账号",
                        type: 'warning',
                    })
                    return
                }

                if (!this.alipay_name || !this.alipay_name.trim().length) {
                    this.tixian_value = false
                    ElMessage({
                        showClose: true,
                        message: "请绑定支付宝账号",
                        type: 'warning',
                    })
                    return
                }

                var res = await Post({
                    url: '/api/tixian',
                    body: {
                        tixian_price: this.tixian_price
                    }
                })
                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                    this.tixian_windows_value = false
                    this.$emit('referrer_money_change', '');
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                }
                this.tixian_value = false

            },
        },
    }
</script>

<style scoped>
    .tixian_bg {
        width: 482px;
        height: 135px;
        background-color: #4975e9;
        border-radius: 8px;
        margin-bottom: 30px;
        background: url(../../img/tixian_bg.png) no-repeat 50%;
    }

    .buttons {
        width: 100px;
        height: 40px;
        background-color: #4975e9;
        border-radius: 10px;
        cursor: pointer;
    }

    .buttons:hover {
        background-color: #7998ee;
    }


    .inputs {
        width: 300px;
        height: 45px;
        border: 1px #b4bccc solid;
        margin-bottom: 30px;
        border-radius: 5px;
    }

    input {
        width: 75%;
        height: 45px;
        font-size: 14px;
        color: #606266;
    }

    .input-icon {
        width: 19px;
        height: 19px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .items2:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .windows {
        background-color: #fff;
        border-radius: 10px;
    }

    .buyVip {
        padding: 7px 13px 7px 13px;
        background-color: #4975e9;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
    }

    .buyVip:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .UserAttachWindowd-item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px #ededed solid;
        font-size: 14px;
        color: #666;
    }

    .UserAttachWindowd-item-hover:hover {
        color: #4975e9;
        cursor: pointer;
    }

    .user-attach-window {
        min-width: 233px;
        position: absolute;
        margin-top: 39px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        box-shadow: 0 0 5px 3px rgba(146, 146, 146, 0.3);
        border-radius: 5px;
        animation: run 0.63s;
        z-index: 999;
    }

    @keyframes run {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .user {
        cursor: pointer;
        color: #666;
        text-decoration: underline;
    }

    .user:hover {
        color: #4975e9;
    }

    .Head-Navigations {
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        box-shadow: 0px 10px 10px -10px rgba(146, 146, 146, 0.279);
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .Head-Navigation {
        width: 70%;
        height: 100%;
    }

    .Head-Navigation-itme {
        width: 120px;
        height: 76px;
        color: #666666;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 15px;
        cursor: pointer;
    }

    .Head-Navigation-itme:hover {
        color: #4975e9;
    }

    .Head-Navigation-itme-Select {
        width: 70px;
        height: 2px;
        background-color: #4975e9;
    }

    .Login-Button {
        width: 105px;
        height: 40px;
        background-color: #4975e9;
        color: #ffffff;
        border-radius: 5px;
        font-size: 13px;
        cursor: pointer;
    }

    .Login-Button:hover {
        background-color: #7998ee;
    }
</style>