<template>
    <div v-if="pop_up_windows_value" class="Mask j-f-c-j-c">

        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;">
            <img class="items" @click="on_update_password_windows"
                :style="'width:'+ pop_up_info.width + ';' +'height:'+ pop_up_info.height" :src="
                pop_up_info.img">

            <span @click="long_off_pop_up_windows"
                style="font-size: 14px;margin-top: 20px;color: #fff;cursor: pointer;">不再提示</span>

            <div class="j-f-c-j-c" @click="off_pop_up_windows"
                style="width: 30px;height: 30px;font-size: 40px;color: #ffffff;position: absolute;top: -30px;right: -30px;cursor: pointer;">
                ×
            </div>
            <div @click="long_off_pop_up_windows" v-if="pop_up_info.pop_up_id=='pop_up02346'"
                style="width: 324px;height: 70px;position: absolute;top: 436px;right: 58px;cursor: pointer;">
            </div>
        </div>
    </div>

</template>

<script>
    import { GetCookie, SetCookie } from '../../js/cookies.js' //注意路径

    export default {
        name: 'LeftNav',
        data () {
            return {
                pop_up_windows_value: false,
            }
        },
        components: {

        },
        created () {
            //判断当前页面路径
            this.path = this.$route.fullPath
            this.get_pop_up()
        },
        methods: {
            long_off_pop_up_windows () {
                this.pop_up_windows_value = false
                SetCookie({
                    [this.pop_up_info.pop_up_id]: true
                })
            },
            off_pop_up_windows () {
                this.pop_up_windows_value = false
            },
            get_pop_up () {
                var pop_up_info = {
                    pop_up_id: 'pop_up02346',
                    img: require('../../img/pop_up_id_02346.png'),
                    width: '442px',
                    height: '600px',
                }

                var pop_up_id_cookie = GetCookie(pop_up_info.pop_up_id)
                if (!pop_up_id_cookie) {
                    // this.pop_up_windows_value = true
                    this.pop_up_info = pop_up_info
                }

            },
            to_page (e) {
                this.$router.push({ path: e.path })
            },
        },
    }
</script>

<style scoped>
    .nav_item {
        color: #444444;
        font-weight: bold;
        width: 100%;
        height: 54px;
        cursor: pointer;
        padding-left: 35px;
    }

    .nav_item_Select {
        color: #4975e9;
        font-weight: bold;
        width: 100%;
        height: 54px;
        cursor: pointer;
        padding-left: 35px;
        background-color: #f4f7ff;
    }

    .left_nav {
        width: 200px;
        height: 600px;
        background-color: #fff;
        margin-right: 20px;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        padding-top: 34px;
    }

    .left_nav:before {
        content: "";
        display: inline-block;
        width: 160px;
        height: 6px;
        background: #4975e9;
        border-radius: 4px;
        position: absolute;
        top: -3px;
        left: 50%;
        margin-left: -80px;
    }
</style>