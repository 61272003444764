<template>

    <HeadNav></HeadNav>
    <div class="hytqs j-f-c-j-c" style="height: 580px;background-color: #f5f7fa;"
        :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">
        <div class="j-f-c-j-c"
            style="width: 379px;height: 500px;box-shadow: 0 0 10px 3px rgb(146 146 146 / 28%);border-radius: 10px;background-color: #fff;">
            <div style="width: 290px;margin-bottom: 30px;" class="j-f-r-c">
                <img style="width: 45px;height: 45px;margin-right: 10px;" :src="branch_info.waiter_logo">
                <div>
                    <div style="font-weight: bold;">{{branch_info.branch_name}} 客服微信</div>
                    <div style="font-size: 13px;color:#666;">咨询/充值/找回密码，请直接添加好友</div>
                </div>
            </div>
            <img style="width: 290px;height: 290px;" :src="branch_info.waiter_ewm">
        </div>
    </div>
    <FootNav></FootNav>

</template>

<script>
    import HeadNav from './dist/HeadNav.vue'
    import FootNav from './dist/FootNav.vue'
    import { GetCookie } from '../js/cookies.js' //注意路径
    export default {
        components: {
            HeadNav,
            FootNav,
        },
        data () {
            return {
                branch_info: '',
                browserWidth: '',
            }
        },
        async created () {
            this.browserWidth = window.innerWidth

            var While_Value = false
            while (!While_Value) {
                var branch_info = GetCookie('branch_info')
                if (branch_info) {
                    this.branch_info = JSON.parse(branch_info)
                    break
                }
                await this.outTime(500)
            }
        },
        methods: {
            async outTime (time) {
                return new Promise((resolve) => {
                    setTimeout(function () { resolve() }, time)
                })
            }
        }
    }
</script>

<style scoped>

</style>