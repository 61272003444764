import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import App from './App.vue'


const routes = [
    {
        path: '/',
        component: () => import('./components/logistics_code_list.vue')
    },
    {
        path: '/logistics_code_list',
        component: () => import('./components/logistics_code_list.vue')
    },
    {
        path: '/login',
        component: () => import('./components/login.vue')
    },
    {
        path: '/recharge',
        component: () => import('./components/recharge.vue')
    },
    {
        path: '/waiter',
        component: () => import('./components/waiter.vue')
    },
    {
        path: '/help',
        component: () => import('./components/help.vue')
    },
    {
        path: '/order_list',
        component: () => import('./components/order_list.vue')
    },
    {
        path: '/recharge_list',
        component: () => import('./components/recharge_list.vue')
    },
    {
        path: '/pop_money',
        component: () => import('./components/pop_money.vue')
    }, {
        path: '/branch_manage',
        component: () => import('./components/branch_manage.vue')
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
const vm = createApp(App)
vm.use(router)
vm.use(ElementPlus, { locale: zhCn })
vm.mount('#app')

