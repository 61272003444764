<template>
    <div class="Foot-Navigations j-f-c-c" :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">
        <div class="Foot-Navigation">
            <div style="margin-bottom: 30px;" class="j-s-b-no-a-c ">
                <div class="j-f-r">
                    <div style="margin-right:100px;">
                        <img :src="branch_info.foot_logo" class="Head-Navigation-Logo">
                        <div style="font-size: 12px;color:#999999;margin-top: 20px;">
                            真实物流，真实轨迹，保证单号真实有效
                        </div>
                    </div>
                    <div class="j-f-r" style="color:#999999;font-size: 13px;">
                        <div style="margin-right:80px;">
                            <div style="font-size:16px;color:#ffffff;margin-bottom: 30px;">产品服务</div>
                            <div style="margin-bottom: 30px;">快递单号</div>
                            <div style="margin-bottom: 30px;">开发平台</div>
                        </div>
                        <!-- <div>
                            <div style="font-size:16px;color:#ffffff;margin-bottom: 30px;">联系方式</div>
                            <div style="margin-bottom: 30px;">QQ:100000</div>
                        </div> -->
                    </div>
                </div>
                <div class="j-f-r">
                    <div class="j-f-c-c">
                        <div style="color: #ffffff;padding-bottom: 10px;">客服微信</div>
                        <img style="width:100px;height: 100px;border: 10px #ffffff solid;border-radius: 4px;"
                            :src="branch_info.waiter_ewm">
                    </div>
                </div>
            </div>
            <div class="j-f-c-j-c"
                style="padding-top:20px;padding-bottom: 20px;border-top: 1px #999999 solid;color: #999999;font-size: 13px;">
                Copyright 2019-2024 © {{branch_info.branch_name}} . 版权所有
            </div>
        </div>
    </div>
</template>

<script>
    import { GetCookie } from '../../js/cookies.js' //注意路径
    export default {
        name: 'FootNav',
        data () {
            return {
                branch_info: '',
                browserWidth: '',
            }
        },
        async created () {
            this.browserWidth = window.innerWidth

            var While_Value = false
            while (!While_Value) {
                var branch_info = GetCookie('branch_info')
                if (branch_info) {
                    this.branch_info = JSON.parse(branch_info)
                    break
                }
                await this.outTime(500)
            }

        },
        methods: {
            async outTime (time) {
                return new Promise((resolve) => {
                    setTimeout(function () { resolve() }, time)
                })
            }
        }
    }
</script>

<style scoped>
    .Foot-Navigations {
        background-color: #292b33;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .Foot-Navigation {
        width: 1344px;
    }
</style>