<template>
    <div v-if="!top_banner_off">
        <img @click="to_page({path:'/waiter'})"
            style="height: 50px; min-width: 1380px;text-align: left;cursor: pointer;"
            :style="browserWidth>1523?'width: 100%;':'width: 1523px;'" src="../../img/top_banner.png">
        <div @click="off_top_banner"
            style="position: fixed;right: 20px;top: 2px;font-size: 30px;color: #fff;cursor: pointer;">×</div>
    </div>

    <div class="Head-Navigations j-f-c-c" :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">
        <div class="Head-Navigation j-s-b" style="width:1344px;">
            <div class="j-f-r-c" style="height: 100%;">
                <img @click="to_page({path:'/'})" style="cursor: pointer;" :src="branch_info.head_logo"
                    class="Head-Navigation-Logo">

                <div @click="to_page({path:'/logistics_code_list'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">单号大厅</div>
                    <div v-if="path == '/' || path == '/logistics_code_list'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div v-if="account" @click="to_page({path:'/recharge'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">充值中心</div>
                    <div v-if="path == '/recharge'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div @click="to_page({path:'/help'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">新手帮助</div>
                    <div v-if="path == '/help'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div @click="to_page({path:'/waiter'})" class=" j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">联系客服</div>
                    <div v-if="path == '/waiter'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div v-if="account" @click="to_page({path:'/recharge_list'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">个人中心</div>
                    <div v-if="path == '/recharge_list'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div v-if="account" @click="to_page({path:'/order_list'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">我的订单</div>
                    <div v-if="path == '/order_list'" class="Head-Navigation-itme-Select">
                    </div>
                </div>

                <div v-if="account" @click="to_page({path:'/pop_money'})" class="j-f-c-j-c">
                    <div class="Head-Navigation-itme j-f-c-j-c">推广赚钱</div>
                    <div v-if="path == '/pop_money'" class="Head-Navigation-itme-Select">
                    </div>
                </div>


            </div>
            <div class="j-f-r-c">
                <div v-if="account" class="j-f-c-c">
                    <div class="user" @mouseenter="onUserAttachWindowd" @mouseleave="offUserAttachWindowd"
                        @click="onUserAttachWindowd">{{ account }}
                    </div>
                    <div @mouseenter="onUserAttachWindowd" @mouseleave="offUserAttachWindowd"
                        v-if="UserAttachWindowdValue" class="user-attach-window">
                        <div v-if="user">
                            <div style="font-weight: bold;cursor: pointer;" class="UserAttachWindowd-item j-f-r-c"
                                @click="to_page({path:'/recharge_list'})">
                                <img style="width: 20px;height: 20px;margin-right:8px;"
                                    src="../../img/icon-phone-666.png">
                                <div>{{ user.account }}</div>
                            </div>

                            <div v-if="user.branch_admin" style="font-weight: bold;"
                                class="UserAttachWindowd-item UserAttachWindowd-item-hover j-f-r-c"
                                @click="to_page({path:'/branch_manage'})">
                                <img style="width: 20px;height: 20px;margin-right:8px;" src="../../img/icon_admin.png">
                                <div>站点管理</div>
                            </div>

                            <div style="font-weight: bold;cursor: pointer;" class="UserAttachWindowd-item j-f-r-c"
                                @click="to_page({path:'/recharge_list'})">
                                <img style="width: 20px;height: 20px;margin-right:8px;" src="../../img/ye.png">
                                <div>账号剩余：{{ user.money }}</div>
                            </div>

                            <div class="UserAttachWindowd-item j-s-b">
                                <div style="margin-top: 5px;margin-bottom: 5px;">
                                    <div class="j-f-r-c">
                                        <img style="width: 20px;height: 20px;margin-right:3px;"
                                            :src="require('../../img/' + (user.vip == 'VIP会员' ? 'icon-vip-ersonaleition' : '') + (user.vip !== '普通会员'&&user.vip !== 'VIP会员' ? 'icon-vip-professionaledition' : '') + (user.vip == '普通会员' ? 'icon-novip' : '') + '.png')">
                                        <div
                                            :style="(user.vip == '普通会员' ? 'color:#666;' : '') + (user.vip == 'VIP会员' ? 'color:#f7b500;' : '') + (user.vip !== '普通会员'&&user.vip !== 'VIP会员' ? 'color:#6236ff;' : '')">
                                            {{ user.vip
                                            }}</div>
                                    </div>

                                    <div v-if="user.vip !== '普通会员'"
                                        :style="(user.vip_end_time_value ? 'color:red;' : 'color:#999;') + 'font-size:12px'">
                                        {{user.vip_end_time}}到期</div>
                                </div>
                            </div>

                            <div style="font-weight: bold;"
                                class="UserAttachWindowd-item UserAttachWindowd-item-hover j-f-r-c"
                                @click="on_update_password_windows">
                                <img style="width: 20px;height: 20px;margin-right:8px;" src="../../img/bj.png">
                                <div>修改密码</div>
                            </div>

                            <div style="font-weight: bold;"
                                class="UserAttachWindowd-item UserAttachWindowd-item-hover j-f-r-c" @click="exitUser">
                                <img style="width: 20px;height: 20px;margin-right:8px;"
                                    src="../../img/icon-exit-666.png">
                                <div>退出</div>
                            </div>

                        </div>
                        <div v-if="!user" style="height: 150px;" class="j-f-c-j-c">
                            <img style="width: 39px;height: 39px;" src="../../img/load.gif">
                        </div>
                    </div>
                </div>
                <input v-if="!account" @click="to_page({path:'/login'})" class="Login-Button" type="button"
                    value="登录/注册" />
            </div>
        </div>
    </div>

    <div v-if="update_password_windows_value" class="Mask j-f-c-j-c">

        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;padding: 50px;">
            <div class="j-f-c-j-c">
                <div style="font-size: 18px;font-weight: bold;color: #666;margin-bottom: 30px;">修改密码
                </div>
                <div class="j-f-r-c inputs">
                    <img class="input-icon" src="../../img/icon-login-password.png">
                    <input type="password" placeholder="请输入旧密码"
                        @input="InputChange({ key: 'out_password', event: $event })">
                </div>
                <div class="j-f-r-c inputs">
                    <img class="input-icon" src="../../img/icon-login-password.png">
                    <input type="password" placeholder="请输入新密码"
                        @input="InputChange({ key: 'new_password1', event: $event })">
                </div>
                <div class="j-f-r-c inputs">
                    <img class="input-icon" src="../../img/icon-login-password.png">
                    <input type="password" placeholder="请再次输入新密码"
                        @input="InputChange({ key: 'new_password2', event: $event })">
                </div>
                <div class="buttons j-f-c-j-c" @click="update_password">
                    <LoadAnimation v-if="update_password_value"></LoadAnimation>
                    <button v-if="!update_password_value" style="color: #fff;font-weight: bold;">确认修改</button>
                </div>
            </div>

            <img class="items" @click="on_update_password_windows"
                style="width: 25px;height: 25px;position: absolute;top: 0px;right: 0px;padding: 20px;cursor: pointer;"
                src="../../img/icon-close666.png">
        </div>
    </div>

</template>

<script>
    import { GetCookie, DelAllCookie, SetCookie } from '../../js/cookies.js' //注意路径
    import { Get, Post } from '../../js/axios.js'
    import { ElMessage } from 'element-plus'
    import LoadAnimation from './LoadAnimation.vue'

    export default {
        name: 'HeadNav',
        data () {
            return {
                branch_info: '',
                browserWidth: '',
                top_banner_off: false,
                update_password_value: false,
                out_password: '',
                new_password1: '',
                new_password2: '',
                loginewmMaskValue: false,
                account: '',
                UserAttachWindowdValue: false,
                UserAttachWindowdValueJ: false,
                user: false,
                update_password_windows_value: false
            }
        },
        components: {
            LoadAnimation
        },
        created () {
            this.browserWidth = window.innerWidth
            //判断当前页面路径
            this.path = this.$route.fullPath
            var account = GetCookie('account')
            if (account) {
                this.account = account
            }
            var top_banner_off = GetCookie('top_banner_off')
            if (top_banner_off) {
                this.top_banner_off = top_banner_off
            }
            this.get_branch_info()
        },
        methods: {
            async get_branch_info () {

                var branch_info = GetCookie('branch_info')
                if (branch_info) {
                    this.branch_info = JSON.parse(branch_info)
                }

                var host = window.location.hostname
                // host = '99danhao.jiandianyouxuan.cn'
                var res = await Get({
                    url: "/api/get_branch_info",
                    data: {
                        host
                    },
                });

                SetCookie({
                    branch_info: JSON.stringify(res.data)
                })

                this.branch_info = res.data
                document.title = res.data.branch_name
                var link = document.querySelector("link[rel*='icon']")
                link.href = res.data.icon
            },

            off_top_banner () {
                SetCookie({
                    top_banner_off: true
                })
                this.top_banner_off = true
            },

            InputChange: function (e) {
                this[e.key] = e.event.target.value
            },

            async update_password () {

                if (this.update_password_value) {
                    return
                }
                this.update_password_value = true

                if (!this.out_password || !this.out_password.trim().length) {
                    this.update_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入旧密码",
                        type: 'warning',
                    })
                    return
                }

                if (!this.new_password1 || !this.new_password1.trim().length) {
                    this.update_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "请输入新密码",
                        type: 'warning',
                    })
                    return
                }

                if (!this.new_password2 || !this.new_password2.trim().length) {
                    this.update_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "请再次输入新密码",
                        type: 'warning',
                    })
                    return
                }

                if (this.new_password1 !== this.new_password2) {
                    this.update_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "两次密码输入不正确请重新输入",
                        type: 'warning',
                    })
                    return
                }

                if (this.new_password1.trim().length < 6) {
                    this.update_password_value = false
                    ElMessage({
                        showClose: true,
                        message: "请设置6位以上密码",
                        type: 'warning',
                    })
                    return
                }

                var res = await Post({
                    url: "/api/update_password",
                    body: {
                        out_password: this.out_password,
                        new_password: this.new_password1,
                    },
                });
                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                    this.on_update_password_windows()
                    SetCookie(res.data)
                    //设置新token
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                }

                this.update_password_value = false

            },

            on_update_password_windows () {
                this.out_password = ''
                this.new_password1 = ''
                this.new_password2 = ''
                this.update_password_value = false
                this.update_password_windows_value = !this.update_password_windows_value
            },

            to_page (e) {
                this.$router.push({ path: e.path })
            },

            offUserAttachWindowd: function () {
                var thas = this
                thas.UserAttachWindowdValueJ = false
                setTimeout(function () {
                    if (!thas.UserAttachWindowdValueJ) {
                        thas.UserAttachWindowdValue = false
                    }
                }, 533)
            },

            async onUserAttachWindowd () {
                this.UserAttachWindowdValue = true
                this.UserAttachWindowdValueJ = true
                if (!this.user) {
                    var host = window.location.hostname
                    // host = '99danhao.jiandianyouxuan.cn'
                    var thas = this
                    var token = GetCookie('token')
                    if (token) {
                        var res = await Get({
                            url: '/api/get_user_info',
                            data: {
                                host
                            }
                        })
                        console.log(res)
                        if (res.code == 200) {
                            thas.user = res.data
                        }
                    }

                }
            },

            to_logistics_code_list: function () {
                this.$router.push({ path: '/logistics_code_list' })
            },

            to: function () {
                ElMessage({
                    showClose: true,
                    message: '权限不足！此板块为会员功能，开通会员后可进入。',
                    type: 'warning',
                })
                return
            },

            exitUser: function () {
                this.UserAttachWindowdValue = false
                this.UserAttachWindowdValueJ = false
                DelAllCookie()
                this.$router.go(0)
            }
        },
    }
</script>

<style scoped>
    .buttons {
        width: 320px;
        height: 45px;
        background-color: #4975e9;
        border-radius: 5px;
        cursor: pointer;
    }

    .buttons:hover {
        background-color: #7998ee;
    }


    .inputs {
        width: 320px;
        height: 45px;
        border: 1px #b4bccc solid;
        margin-bottom: 30px;
        border-radius: 5px;
    }

    input {
        width: 75%;
        height: 45px;
        font-size: 14px;
        color: #606266;
    }

    .input-icon {
        width: 19px;
        height: 19px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .items2:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .windows {
        background-color: #fff;
        border-radius: 10px;
    }

    .buyVip {
        padding: 7px 13px 7px 13px;
        background-color: #4975e9;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
    }

    .buyVip:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .UserAttachWindowd-item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px #ededed solid;
        font-size: 14px;
        color: #666;
    }

    .UserAttachWindowd-item-hover:hover {
        color: #4975e9;
        cursor: pointer;
    }

    .user-attach-window {
        min-width: 233px;
        position: absolute;
        margin-top: 39px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        box-shadow: 0 0 5px 3px rgba(146, 146, 146, 0.3);
        border-radius: 5px;
        animation: run 0.63s;
        z-index: 999;
    }

    @keyframes run {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .user {
        cursor: pointer;
        color: #666;
        text-decoration: underline;
    }

    .user:hover {
        color: #4975e9;
    }

    .Head-Navigations {
        height: 80px;
        background-color: #ffffff;
        box-shadow: 0px 10px 10px -10px rgba(146, 146, 146, 0.279);
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .Head-Navigation {
        width: 70%;
        height: 100%;
    }

    .Head-Navigation-itme {
        width: 120px;
        height: 76px;
        color: #666666;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 15px;
        cursor: pointer;
    }

    .Head-Navigation-itme:hover {
        color: #4975e9;
    }

    .Head-Navigation-itme-Select {
        width: 70px;
        height: 2px;
        background-color: #4975e9;
    }

    .Login-Button {
        width: 105px;
        height: 40px;
        background-color: #4975e9;
        color: #ffffff;
        border-radius: 5px;
        font-size: 13px;
        cursor: pointer;
    }

    .Login-Button:hover {
        background-color: #7998ee;
    }
</style>