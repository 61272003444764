<template>
    <router-view></router-view>

    <HeadNav></HeadNav>

    <!-- <ChoiceNav :itmes="ChoiceNavItmes"></ChoiceNav> -->

    <div class="j-f-c-j-c" style="padding-top: 50px;" :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">

        <div style="width: 1250px; margin-bottom: 30px" class="j-s-b">
            <div class="j-f-r-c">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    选择平台：
                </div>
                <div class="j-f-r-c">

                    <div v-for="(item, index) in logistics_code_yes_pingtai_list" :key="index"
                        @click="logistics_code_yes_pingtai_list_change(item)">
                        <div class="j-f-c-j-c hover_color_7998ee"
                            :style="item.value ? 'background-color: #4975e9;color: #fff;' : ''"
                            style="cursor: pointer;margin-left: 20px;margin-right: 20px;padding-left: 20px;padding-right: 20px;padding-bottom: 8px;padding-top: 8px;border-radius: 999px;font-size: 15px;">
                            {{item.name}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="width: 1250px; margin-bottom: 20px" class="j-s-b">
            <div class="j-f-r">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    智能识别：
                </div>
                <div class="j-f-r-c">
                    <el-input v-model="ai_adds" style="width: 470px" placeholder="请粘贴收货信息，可自动识别匹配收货信息" clearable
                        @input="el_input_change({ key: 'ai_adds', event: $event })" />
                </div>
                <div @click="on_ai_adds" v-if="ai_adds" class="j-f-r-c"
                    style="margin-left: 10px;padding:0px 20px 0px 20px;font-size: 14px;text-decoration: underline;color: #4975e9;cursor: pointer;">
                    识 别
                </div>
            </div>
        </div>
        <!-- <div style=" width: 1250px; margin-bottom: 20px" class="j-s-b">
            <div class="j-f-r">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    发货地址：
                </div>
                <div class="j-f-r-c">
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="senderProvince" class="m-2" placeholder="选择省份"
                            clearable size="default" @change="SelectChange({ key: 'senderProvince', event: $event })">
                            <el-option v-for="item in all_area_list" :key="item.name" :label="item.name"
                                :value="item.name" />
                        </el-select>
                    </div>
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="senderCity" class="m-2" placeholder="选择城市" clearable
                            size="default" @change="SelectChange({ key: 'senderCity', event: $event })">
                            <el-option
                                v-for="item in senderProvince_index!==999?all_area_list[senderProvince_index].children:[]"
                                :key="item.name" :label="item.name" :value="item.name" />
                        </el-select>
                    </div>
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="senderCounty" class="m-2" placeholder="选择县区" clearable
                            size="default" @change="SelectChange({ key: 'senderCounty', event: $event })">
                            <el-option
                                v-for="item in senderProvince_index!==999&&senderCity_index!==999?all_area_list[senderProvince_index].children[senderCity_index].children:[]"
                                :key="item.name" :label="item.name" :value="item.name" />
                        </el-select>

                    </div>
                </div>
            </div>
        </div> -->
        <div style="width: 1250px; margin-bottom: 20px" class="j-s-b">
            <div class="j-f-r">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    收货地址：
                </div>
                <div class="j-f-r-c">
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="receiverProvince" class="m-2" placeholder="选择省份"
                            clearable size="default" @change="SelectChange({ key: 'receiverProvince', event: $event })">
                            <el-option v-for="item in all_area_list" :key="item.name" :label="item.name"
                                :value="item.name" />
                        </el-select>
                    </div>
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="receiverCity" class="m-2" placeholder="选择城市" clearable
                            size="default" @change="SelectChange({ key: 'receiverCity', event: $event })">
                            <el-option
                                v-for="item in receiverProvince_index!==999?all_area_list[receiverProvince_index].children:[]"
                                :key="item.name" :label="item.name" :value="item.name" />
                        </el-select>
                    </div>
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 120px" v-model="receiverCounty" class="m-2" placeholder="选择县区"
                            clearable size="default" @change="SelectChange({ key: 'receiverCounty', event: $event })">
                            <el-option
                                v-for="item in receiverProvince_index!==999&&receiverCity_index!==999?all_area_list[receiverProvince_index].children[receiverCity_index].children:[]"
                                :key="item.name" :label="item.name" :value="item.name" />
                        </el-select>

                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1250px; margin-bottom: 20px">
            <div class="j-f-r">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    日期范围：
                </div>
                <div class="j-f-r-c">
                    <div class="j-f-r-c" style="margin-right: 10px">
                        <el-date-picker @change="time_change()" v-model="start_time" type="datetime"
                            format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择开始时间" />
                    </div>
                    -
                    <div class="j-f-r-c" style="margin-left: 10px">
                        <el-date-picker @change="time_change()" v-model="end_time" type="datetime"
                            format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择结束时间" />
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1250px; margin-bottom: 20px" class="j-s-b">
            <div class="j-f-r">
                <div class="Tabstitle" style="margin-right: 10px; margin-top: 5px">
                    承运公司：
                </div>
                <div class="j-f-r-c">
                    <div class="j-f-r-c" style="margin-right: 30px">
                        <el-select style="width: 180px" v-model="logistics_code_carrier_company" class="m-2"
                            placeholder="选择承运公司" size="default" clearable
                            @change="SelectChange({ key: 'logistics_code_carrier_company', event: $event })">
                            <el-option v-for="item in logistics_code_carrier_company_list" :key="item.name"
                                :label="item.name" :value="item.name" />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="j-f-r-c">
                <div @click="query_logistics_code_list" class="j-f-c-j-c query1">查 询</div>
                <div @click="reset_logistics_code_list" class="j-f-c-j-c reset1">重 置</div>
            </div>
        </div>



        <div class="hytqs j-f-c-j-c" style="padding-top: 10px;width: 1250px;">
            <div class="j-f-c-j-c-no-a-c">
                <div class="j-f-r-c" style="
              color: #737674;
              font-size: 15px;
              background-color: #f6f8fe;
              line-height: 50px;
              position: sticky;
              top: 80px;
              z-index: 998;
            ">
                    <div class="j-f-c-j-c" style="width: 120px">

                        <el-popover placement="top-start" title="选择平台" :width="200" trigger="hover"
                            content="切勿选错，不然会被判定虚假物流。选错不退款">
                            <template #reference>
                                <div class="j-f-r-c">
                                    <div>可用平台</div>
                                    <div class="j-f-c-j-c"
                                        style="width: 12px;height: 12px;border-radius:50%;border:1px #888 solid;color:#888;font-size:12px;margin-left:2px;">
                                        ?</div>
                                </div>
                            </template>
                        </el-popover>

                    </div>
                    <div class="j-f-c-j-c" style="width: 150px">快递单号</div>
                    <div class="j-f-c-j-c" style="width: 150px">承运公司</div>
                    <div class="j-f-c-j-c" style="width: 240px">发货地址</div>
                    <div class="j-f-c-j-c" style="width: 240px">收货地址</div>
                    <div class="j-f-c-j-c" style="width: 150px">

                        <el-popover placement="top-start" title="打单日期选择" :width="200" trigger="hover"
                            content="要选在你订单时间之后的">
                            <template #reference>
                                <div class="j-f-r-c">
                                    <div>打单日期</div>
                                    <div class="j-f-c-j-c"
                                        style="width: 12px;height: 12px;border-radius:50%;border:1px #888 solid;color:#888;font-size:12px;margin-left:2px;">
                                        ?</div>
                                </div>
                            </template>
                        </el-popover>

                    </div>
                    <div class="j-f-c-j-c" style="width: 200px">购买单号</div>
                    <!-- <div class="j-f-c-j-c" style="width: 150px;">备注</div> -->
                </div>
                <div v-if="load ? false : true" class="j-f-r-c-j-c-c">
                    <img style="
                width: 39px;
                height: 39px;
                margin-top: 200px;
                margin-bottom: 1000px;
              " src="../img/load.gif" />
                </div>
                <div style="position: relative" v-if="load ? true : false">
                    <!-- @click="toDOUYINGoods(item.promotion_id, index)" -->
                    <div class="j-f-r-c ListItems" :style="item.value ? 'color: #999;' : 'color: #333;'"
                        v-for="(item, index) in list" :key="index">
                        <div class="j-f-r-c-j-c-c " style="width: 120px">


                            <div>
                                {{ logistics_code_list_yes_pingtai_value }}
                            </div>
                            <img style="width: 16px;height: 16px;margin-left: 3px;" src="../img/icon_yes.png">
                        </div>
                        <div class="j-f-c-j-c" style="width: 150px">
                            {{ item.logistics_code_m }}
                        </div>
                        <div class="j-f-c-j-c" style="width: 150px">
                            {{ item.logistics_code_carrier_company }}
                        </div>
                        <div class="j-f-c-j-c" style="width: 240px">
                            {{ item.senderProvince }} {{ item.senderCity }}
                            {{ item.senderCounty }}
                        </div>
                        <div class="j-f-c-j-c" style="width: 240px">
                            {{ item.receiverProvince }} {{ item.receiverCity }}
                            {{ item.receiverCounty }}
                        </div>
                        <div class="j-f-c-j-c list-item-button" style="width: 150px;"
                            @click="on_logistics_code_trajectory_WindowMask(item)">
                            {{ item.createdTime }}
                        </div>

                        <!-- 代码写这里 -->
                        <div v-if="account && !item.logistics_code" @click="buy_logistics_code(item,index)"
                            class="j-f-c-j-c list-item-button" style="width: 200px">
                            购买单号
                        </div>

                        <div v-if="item.logistics_code" class="j-f-c-j-c" style="width: 200px">
                            {{ item.logistics_code }}
                        </div>

                        <!-- 这个是没有登录的按钮，无反应 -->
                        <div v-if="!account" class="j-f-c-j-c list-item-button" style="width: 200px">
                            购买单号
                        </div>

                    </div>
                    <div class="no-vip-Mask" v-if="!account">
                        <div class="no-vip-Mask-button j-f-r-c" @click="to_page({path:'/login'})">
                            <img style="width: 20px; height: 20px; margin-right: 8px" src="../img/icon-lock.png" />
                            <div>登录 查看更多</div>
                        </div>
                    </div>

                </div>
            </div>
            <el-empty v-if="!list.length &&load" description="暂无资源，中午过后单号资源比较多" />
            <div v-if="account" style="margin-top: 80px">
                <el-pagination :current-page="page" @current-change="PaginationChange" background
                    layout="prev, pager, next" :page-count="page_count" />
            </div>

        </div>
    </div>

    <div @click="on_logistics_code_trajectory_WindowMask" v-if="logistics_code_trajectory_WindowMask_value"
        class="Mask j-f-c-j-c">

        <div @click.stop="kk" class="windows j-f-c-j-c" style="position: sticky;padding: 50px;">
            <div class="j-f-c-j-c">
                <div style="font-size: 18px;font-weight: bold;color: #666;">物流轨迹
                </div>
                <div style="margin-bottom: 30px;font-size: 14px;color: #999;">实际物流轨迹请前往官网查询
                </div>
                <el-timeline style="max-width: 600px">
                    <el-timeline-item v-for="(item, index) in logistics_code_trajectory" :key="index"
                        :timestamp="item.time">
                        {{ item.context }}
                    </el-timeline-item>
                </el-timeline>
            </div>

            <img class="items" @click="on_logistics_code_trajectory_WindowMask"
                style="width: 25px;height: 25px;position: absolute;top: 0px;right: 0px;padding: 20px;"
                src="../img/icon-close666.png">
        </div>
    </div>
    <pop_up></pop_up>

    <FootNav></FootNav>
</template>


<script>
    import HeadNav from "./dist/HeadNav.vue";
    import FootNav from "./dist/FootNav.vue";
    import pop_up from "./dist/pop_up.vue";
    // import ChoiceNav from './dist/ChoiceNav.vue'
    import { Get, Post } from "../js/axios.js";
    import { GetCookie, SetCookie } from '../js/cookies.js'
    import { get_all_area_list } from '../js/all_area_list.js'
    import { ElMessageBox, ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                browserWidth: '',
                ai_adds: '',
                dialogFormVisible: true,
                logistics_code_yes_pingtai_value: '拼多多',
                logistics_code_list_yes_pingtai_value: '',
                logistics_code_yes_pingtai_list: [
                    {
                        name: '淘宝',
                    },
                    {
                        name: '拼多多',
                        value: true
                    },
                    {
                        name: '抖音'
                    },
                    {
                        name: '快手'
                    },
                    {
                        name: '京东'
                    },
                    {
                        name: '视频号'
                    },
                    {
                        name: '唯品会'
                    },
                    {
                        name: '苏宁易购'
                    }
                ],

                logistics_code_carrier_company: '',
                logistics_code_carrier_company_list: [
                    { name: '中通快递' },
                    { name: '韵达快递' },
                    { name: '邮政快递包裹' },
                    { name: '圆通快递' },
                    { name: '极兔速递' },
                    { name: '申通快递' },
                    { name: '德邦快递' },
                    { name: '顺丰速运' },
                    { name: '京东物流' },
                    { name: '顺心捷达' },
                ],
                all_area_list: [],
                senderProvince_index: 999,
                senderCity_index: 999,
                senderProvince: '',
                senderCity: '',
                senderCounty: '',
                receiverProvince_index: 999,
                receiverCity_index: 999,
                receiverProvince: '',
                receiverCity: '',
                receiverCounty: '',

                start_time: '',
                end_time: '',

                logistics_code_trajectory_WindowMask_value: false,
                logistics_code_trajectory: [],
                list: [],
                page: 0,
                page_count: 0,
                load: false,
                ChoiceNavItmes: [{ name: '单号大厅', path: '/DaORUQG1o2oFdSsSLDiFsCt', value: true }, { name: '导表购买', path: '/DaO2fUdTaarlern2tLtisbt' }],
                queryParams: {}
            };
        },
        components: {
            FootNav,
            HeadNav,
            pop_up
            // ChoiceNav,
        },
        created () {

            var logistics_code_yes_pingtai_value = GetCookie('logistics_code_yes_pingtai_value')
            if (logistics_code_yes_pingtai_value) {
                var list = this.logistics_code_yes_pingtai_list
                for (let i = 0; i < list.length; i++) {
                    if (list[i].name == logistics_code_yes_pingtai_value) {
                        list[i].value = true
                    } else {
                        list[i].value = false
                    }
                }
                this.logistics_code_yes_pingtai_value = logistics_code_yes_pingtai_value
                this.logistics_code_yes_pingtai_list = list
            }

            this.browserWidth = window.innerWidth
            //解析url参数
            this.parseQueryParams()

            var n = ''
            var currentHour = new Date().getHours();
            if (currentHour < 7) {
                n = Date.now() - 1 * 24 * 3600000
            } else {
                n = Date.now()
            }
            var date = new Date(n)
            var Y = date.getFullYear()
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            this.start_time = Y + '-' + M + '-' + D + ' 00:00:00'



            // this.end_time = new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().slice(0, 19).replace('T', ' ')

            this.get_all_area_list();
            this.get_logistics_code_list();
            var account = GetCookie('account')
            if (account) {
                this.account = account
            }


        },
        computed: {
            pageWidth () {
                return window.innerWidth;
            },
        },
        methods: {
            reset_logistics_code_list () {
                var n = ''
                var currentHour = new Date().getHours();
                if (currentHour < 7) {
                    n = Date.now() - 1 * 24 * 3600000
                } else {
                    n = Date.now()
                }
                var date = new Date(n)
                var Y = date.getFullYear()
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
                var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
                this.start_time = Y + '-' + M + '-' + D + ' 00:00:00'
                this.ai_adds = ""
                this.end_time = ""
                this.senderProvince = ""
                this.senderCity = ""
                this.senderCounty = ""
                this.receiverProvince = ""
                this.receiverCity = ""
                this.receiverCounty = ""
                this.logistics_code_carrier_company = ""
                this.page = 0
                this.get_logistics_code_list()
            },
            query_logistics_code_list () {
                this.page = 0
                this.get_logistics_code_list()
            },
            async on_ai_adds () {
                var res = await Post({
                    url: "/api/ai_adds",
                    body: {
                        adds: this.ai_adds
                    },
                    this: this,
                });
                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                    this.receiverProvince = res.data.Province
                    this.receiverCity = res.data.City
                    this.receiverCounty = res.data.County
                    this.page = 0
                    this.get_logistics_code_list()
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'warning',
                    })
                }
            },
            el_input_change (e) {
                this[e.key] = e.event
            },
            parseQueryParams () {
                const search = window.location.search;
                const params = new URLSearchParams(search);
                for (let param of params.entries()) {
                    this.queryParams[param[0]] = param[1];
                }
                if (this.queryParams.i) {
                    SetCookie({
                        i: this.queryParams.i
                    })
                }
            },

            on_logistics_code_trajectory_WindowMask (e) {
                this.logistics_code_trajectory_WindowMask_value = !this.logistics_code_trajectory_WindowMask_value
                this.logistics_code_trajectory = e.logistics_code_trajectory
            },

            PaginationChange (e) {
                this.page = e - 1
                this.get_logistics_code_list()
            },

            time_change () {
                this.page = 0
                this.get_logistics_code_list()
            },

            logistics_code_yes_pingtai_list_change (e) {

                var list = this.logistics_code_yes_pingtai_list
                for (let i = 0; i < list.length; i++) {
                    if (list[i].name == e.name) {
                        list[i].value = true
                    } else {
                        list[i].value = false
                    }
                }
                SetCookie({
                    logistics_code_yes_pingtai_value: e.name
                })
                // this.senderProvince = ""
                // this.senderCity = ""
                // this.senderCounty = ""
                // this.receiverProvince = ""
                // this.receiverCity = ""
                // this.receiverCounty = ""
                // this.logistics_code_carrier_company = ""
                this.logistics_code_yes_pingtai_value = e.name
                this.logistics_code_yes_pingtai_list = list

            },

            SelectChange (e) {
                this[e.key] = e.event

                //========城市选择========
                if (e.key == 'senderProvince') {

                    this.senderProvince_index = 999
                    this.senderCity_index = 999

                    this.senderCity = ''
                    this.senderCounty = ''

                    var c_list = this.all_area_list
                    for (let i = 0; i < c_list.length; i++) {
                        if (c_list[i].name == e.event) {
                            this.senderProvince_index = i
                            break
                        }
                    }
                }

                if (e.key == 'senderCity') {

                    this.senderCity_index = 999
                    this.senderCounty = ''

                    var c_list2 = this.all_area_list[this.senderProvince_index].children
                    for (let i = 0; i < c_list2.length; i++) {
                        if (c_list2[i].name == e.event) {
                            this.senderCity_index = i
                            break
                        }
                    }
                }


                if (e.key == 'receiverProvince') {

                    this.receiverProvince_index = 999
                    this.receiverCity_index = 999

                    this.receiverCity = ''
                    this.receiverCounty = ''

                    var c_list3 = this.all_area_list
                    for (let i = 0; i < c_list3.length; i++) {
                        if (c_list3[i].name == e.event) {
                            this.receiverProvince_index = i
                            break
                        }
                    }
                }

                if (e.key == 'receiverCity') {

                    this.receiverCity_index = 999
                    this.receiverCounty = ''

                    var c_list4 = this.all_area_list[this.receiverProvince_index].children
                    for (let i = 0; i < c_list4.length; i++) {
                        if (c_list4[i].name == e.event) {
                            this.receiverCity_index = i
                            break
                        }
                    }
                }

                //========城市选择========


            },

            to_page (e) {
                this.$router.push({ path: e.path })
            },

            async buy_logistics_code (item, index) {

                ElMessageBox.confirm(
                    `是否购买 ${item.senderProvince}${item.senderCounty}${item.senderCity} - ${item.receiverProvince}${item.receiverCounty}${item.receiverCity} 此单号？`,
                    '购买单号',
                    {
                        confirmButtonText: '购买',
                        cancelButtonText: '取消',
                        type: '',
                    }
                ).then(async () => {
                    var res = await Get({
                        url: "/api/buy_logistics_code",
                        data: {
                            id: item.id,
                            logistics_code_yes_pingtai_value: this.logistics_code_yes_pingtai_value,
                        },
                        this: this,
                    })
                    if (res.code == 200) {
                        ElMessageBox.alert(`${item.logistics_code_carrier_company}：${res.data}`, '单号购买成功', {
                            confirmButtonText: '好的',
                            type: 'success',
                        })
                        this.list[index].logistics_code = res.data
                    } else {
                        ElMessage({
                            showClose: true,
                            message: res.msg,
                            type: 'warning',
                        })
                        return
                    }
                })
            },

            async get_all_area_list () {
                var res = get_all_area_list()
                this.all_area_list = res
            },

            async get_logistics_code_list () {

                this.page = this.page + 1;
                this.load = false;
                this.list = [];
                var res = await Get({
                    url: "/api/get_logistics_code_list",
                    data: {
                        page: this.page - 1,
                        logistics_code_yes_pingtai_value: this.logistics_code_yes_pingtai_value,
                        senderProvince: this.senderProvince,
                        senderCity: this.senderCity,
                        senderCounty: this.senderCounty,
                        receiverProvince: this.receiverProvince,
                        receiverCity: this.receiverCity,
                        receiverCounty: this.receiverCounty,
                        logistics_code_carrier_company: this.logistics_code_carrier_company,

                        start_time: this.start_time,
                        end_time: this.end_time
                    },
                    this: this,
                });
                if (res.code == 200) {
                    this.list = res.data;
                    this.page_count = res.page_count
                    this.load = true;
                    this.logistics_code_list_yes_pingtai_value = this.logistics_code_yes_pingtai_value
                }
            },
        },
    };
</script>

<style scoped>
    .query1 {
        margin-right: 20px;
        border-radius: 4px;
        color: #fff;
        background-color: #1890ff;
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        line-height: 1.499;
        border: 1px solid transparent;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
        cursor: pointer;
    }

    .query1:hover {
        background-color: #40a9ff;
    }

    .reset1 {
        border-radius: 4px;
        color: rgba(0, 0, 0, .65);
        background-color: #fff;
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        line-height: 1.499;
        border: 1px #d9d9d9 solid;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
        cursor: pointer;
    }

    .reset1:hover {
        border: 1px #40a9ff solid;
        color: #40a9ff;
    }

    .hover_color_7998ee:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .hover_color_7998ee {
        color: #333;
    }

    .hover_color_7998ee:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .expanded1 {
        transition: height .3s ease-in-out
    }

    .icon_jt_right {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .icon_jt_right_rotated {
        transform: rotate(90deg);
        /* 如果你想要过渡效果，可以添加 transition 属性 */
        transition: transform 0.3s ease-in-out;
    }

    .icon_jt_right_rotated2 {
        transform: rotate(0deg);
        /* 如果你想要过渡效果，可以添加 transition 属性 */
        transition: transform 0.3s ease-in-out;
    }

    .dylsjl {
        color: #999;
    }

    .dylsjl:hover {
        color: #7998ee;
    }

    .buttons {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 200px;
        height: 50px;
        background-color: #4975e9;
        border-radius: 999px;
        cursor: pointer;
    }

    .buttons:hover {
        background-color: #7998ee;
    }

    .inputs {
        width: 250px;
        height: 45px;
        border: 1px #b4bccc solid;

        border-radius: 5px;
    }

    input {
        padding-left: 20px;
        width: 80%;
        height: 45px;
        font-size: 14px;
        color: #606266;
    }

    .input-icon {
        width: 19px;
        height: 19px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .name_right {
        margin-top: 20px;
        font-size: 12px;
        color: #fa525f;
    }

    .name_right text {
        padding: 5px 12px;
    }

    .hsbs {
        position: fixed;
        right: 10px;
        top: 60%;
        z-index: 9999;
        width: 80px;
        height: 80px;
        background-color: #4975e9;
        border-radius: 50%;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
    }

    .hsbs:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .tabitem {
        align-items: center;
        color: #999;
        display: flex;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        margin: 0 8px 13px 8px;
        text-align: center;
        border-radius: 5px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .tabitem:hover {
        color: #4975e9;
        cursor: pointer;
    }

    .tabitemSelect {
        align-items: center;
        color: #fff;
        background-color: #4975e9;
        display: flex;
        font-size: 15px;
        padding: 5px 15px 5px 15px;
        margin: 0 3px 13px 3px;
        text-align: center;
        border-radius: 999px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .tabitemSelect:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .tabitems-lists {
        display: flex;
        justify-content: space-around;
        display: -webkit-flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .khzssc {
        font-size: 13px;
        color: #999;
        text-decoration: underline;
        margin-left: 10px;
        cursor: pointer;
    }

    .khzssc:hover {
        color: #7998ee;
    }

    .colorbq {
        padding: 5px 10px 5px 10px;
        border: 1px #ddd solid;
        color: #999;
        border-radius: 5px;
        font-size: 13px;
        margin-right: 15px;
        cursor: pointer;
    }

    .colorbq:hover {
        border-color: rgb(99, 206, 255);
        color: rgb(17, 153, 238);
    }

    .colorbqSelect {
        border-color: rgb(99, 206, 255);
        background: rgb(230, 249, 255);
        color: rgb(17, 153, 238);
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .windows {
        background-color: #fff;
        border-radius: 10px;
    }

    .ListItems {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 13px;
        border-bottom: 1px #ebeef5 solid;
        background-color: #fff;
    }

    .ListItems:hover {
        background-color: #f5f5f5;
    }

    .phone2:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .textarea1 {
        border: 1px #ededed solid;
        width: 390px;
        height: 200px;
        border-radius: 10px;
        margin-top: 20px;
        padding: 10px;
        font-size: 16px;
    }

    .Maskbutton1 {
        width: 200px;
        height: 50px;
        background-color: #4975e9;
        color: #fff;
        border-radius: 10px;
        margin-top: 50px;
    }

    .Maskbutton1:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .items:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .Highlights0 {
        font-size: 12px;
        padding: 2px 5px 2px 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        margin-right: 5px;
    }

    .Highlights1 {
        font-size: 12px;
        padding: 2px 5px 2px 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        margin-right: 5px;
    }

    .list-item-button {
        border-radius: 5px;
        color: #333;
        text-decoration: underline;
    }

    .list-item-button:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .no-vip-Mask-button {
        padding: 10px 20px 10px 20px;
        border-radius: 999px;
        border: 1px #4975e9 solid;
        color: #4975e9;
        box-shadow: 0 0 5px 3px rgba(73, 177, 233, 0.1);
        font-size: 15px;
    }

    .no-vip-Mask-button:hover {
        border: 1px #7998ee solid;
        color: #7998ee;
        cursor: pointer;
    }

    .no-vip-Mask {
        width: 100%;
        height: 333px;
        background-image: linear-gradient(to top,
                rgba(255, 255, 255, 1) 30px,
                rgba(255, 255, 255, 0));
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        bottom: 0;
    }

    td {
        border: 1px solid #f5f5f5;
        text-align: center;
        color: #333;
        font-size: 13px;
    }
</style>