<template>

    <HeadNav></HeadNav>
    <div class="hytqs j-f-c-j-c" :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">

        <div style="font-size:30px;color: #333;font-weight: 700;">卡密提取
        </div>

        <div @click="to_Cami_web" style="cursor: pointer;margin-top: 20px;text-decoration: underline;">
            点击跳转卡密购买系统 点击跳转卡密购买系统</div>

        <div class="j-s-b"
            style="width: 400px;height: 50px;border:1px #ededed solid;border-radius: 10px;margin-bottom: 20px;padding: 0 0 0 30px;box-shadow: 0 4px 4px rgb(97 112 241 / 10%);margin-top: 30px;">
            <input style="width: 80%;height: 50px;" placeholder="请输入卡密"
                @input="InputChange({ key: 'cami', event: $event })">
            <div @click="extract_cami" class="j-f-c-j-c Button_color_change"
                style="width: 20%;height: 50px;cursor: pointer;">
                提取
            </div>
        </div>


        <div style="font-size:30px;color: #333;padding-bottom: 50px;font-weight: 700;padding-top: 60px;">充值钜惠
        </div>
        <div>
            <table border="1" style="border-collapse: collapse;">
                <tr>
                    <td colspan="2">
                        <div style="font-size: 20px;color: #333;font-weight: bold;">
                            功能特权
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">10元</div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">100元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">10元</div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">500元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">100元</div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">1000元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">300元</div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">2000元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">800元</div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">3000元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">1500元</div>
                        </div>
                    </td>
                    <td>
                        <div class="j-f-c-j-c" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="j-f-r-a-f">
                                <div style="margin-bottom: 5px;">充值</div>
                                <div class="VIPprice">5000元</div>
                            </div>
                            <div style="margin-bottom: 5px;">送</div>
                            <div class="VIPprice">3000元</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">总到账</td>
                    <td>10元</td>
                    <td>110元</td>
                    <td>600元</td>
                    <td>1300元</td>
                    <td>2800元</td>
                    <td>4500元</td>
                    <td>8000元</td>
                </tr>
                <tr>
                    <td colspan="2">VIP等级</td>
                    <td>VIP会员</td>
                    <td>内部会员svip1</td>
                    <td>内部会员svip2</td>
                    <td>内部会员svip3</td>
                    <td>内部会员svip4</td>
                    <td>内部会员svip5</td>
                    <td>内部会员svip6</td>
                </tr>
                <tr>
                    <td colspan="2">VIP期限</td>
                    <td>6个月</td>
                    <td>永久</td>
                    <td>永久</td>
                    <td>永久</td>
                    <td>永久</td>
                    <td>永久</td>
                    <td>永久</td>
                </tr>
                <tr>
                    <td colspan="2">单号价格</td>
                    <td>￥1</td>
                    <td>￥0.8</td>
                    <td>￥0.7</td>
                    <td>￥0.6</td>
                    <td>￥0.5</td>
                    <td>￥0.4</td>
                    <td>￥0.3</td>
                </tr>


                <tr>
                    <td colspan="2">-</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                    <td>充值不提现、不退款！</td>
                </tr>

            </table>
        </div>

        <!-- <ChoiceNav :itmes="ChoiceNavItmes"></ChoiceNav> -->


    </div>
    <FootNav></FootNav>

</template>

<script>
    import HeadNav from './dist/HeadNav.vue'
    import FootNav from './dist/FootNav.vue'
    // import ChoiceNav from './dist/ChoiceNav.vue'
    import { Post } from '../js/axios.js'
    import { ElMessage, ElMessageBox } from 'element-plus'
    export default {
        components: {
            HeadNav,
            FootNav,
            // ChoiceNav
        },
        data () {
            return {
                browserWidth: '',
                cami: "",
                PayWindowShow: false,
                askPayWindowShow: false,
                LoadAnimationshow: false,
                QueryPayStateText: '支付完成',
                price: 0,
                ChoiceNavItmes: [{ name: '卡密提取', value: true }],
            }
        },
        methods: {
            InputChange: function (e) {
                this[e.key] = e.event.target.value
            },
            to_Cami_web () {

                if (/(micromessenger)/i.test(navigator.userAgent)) {
                    ElMessageBox.alert('微信浏览器暂时无法打开，请使用自带浏览器打开卡密购买系统', '提示', {
                        // if you want to disable its autofocus
                        // autofocus: false,
                        confirmButtonText: '好的',
                        callback: () => {
                            window.open('https://www.poxiaopay.com//links/05AB1DD3')
                        },
                    })

                    //是否电脑微信或者微信开发者工具  微信浏览器暂时无法打开，请使用自带浏览器打开卡密购买系统
                    // if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
                    //     alert('电脑微信或者微信开发者工具')
                    // } else {
                    //     //手机微信打开的浏览器
                    //     alert('手机微信')
                    // }
                } else {
                    window.open('https://www.poxiaopay.com//links/05AB1DD3')
                }

            },
            offPayWindow () {
                this.PayWindowShow = false
            },

            //提取卡密
            async extract_cami () {
                if (this.miValue) {
                    return
                }
                this.miValue = true

                var res = await Post({
                    url: "/api/extract_cami",
                    body: {
                        cami: this.cami,
                    },
                    this: this,
                });

                if (res.code == 200) {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success',
                    })
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'error',
                    })
                }

                this.miValue = false

            },



        },
        created () {
            this.browserWidth = window.innerWidth
        },
    }
</script>

<style scoped>
    .Button_color_change {
        cursor: pointer;
    }

    .Button_color_change:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .PayCompletedButton {
        font-size: 14px;
        padding: 10px 25px 10px 25px;
        border-radius: 5px;
        cursor: pointer;
    }

    .PayWindowBuyVIPButton {
        color: #fff;
        background-color: #4975e9;
        font-size: 14px;
        width: 153px;
        height: 39px;
        border-radius: 5px;
    }

    .PayWindowBuyVIPButton:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .PayWindowVIPcards {
        background: linear-gradient(107.55deg, #fee3c4 9.33%, #fbdab7);
        border: 2px solid #dfbda4;
        color: #4d3020;
        width: 208px;
        height: 96px;
        border-radius: 8px;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .windows {
        width: 500px;
        height: 500px;
        background-color: #fff;
        border-radius: 10px;
    }

    .VIPprice {
        font-size: 24px;
        color: #666;
    }

    .VIPname {
        font-size: 20px;
        color: #333;
        font-weight: 600;
    }

    .VIPfitCrowdText {
        color: #666;
        margin-bottom: 14px;
        margin-top: 12px;
        font-size: 13px;
        font-weight: bold;
    }

    .BuyVipButton {
        width: 120px;
        height: 36px;
        line-height: 36px;
        margin: 17px auto 15px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background: #4975e9;
        border-radius: 18px;
    }

    .BuyVipButton:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    td {
        width: 180px;
        text-align: center;
        border: 1px solid #f5f5f5;
        color: #333;
        font-size: 13px;
        padding-top: 15px;
        padding-bottom: 15px;
        background: #fff;
        vertical-align: middle !important;
    }
</style>