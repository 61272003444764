import axios from 'axios'
import { GetCookie, DelAllCookie } from './cookies.js'
import { ElMessage } from 'element-plus'

var ip = 'https://meiridanhao.api.jiandianyouxuan.cn'

async function Get (e) {

    return new Promise((resolve, reject) => {
        var params = {
            ...e.data,
            token: GetCookie('token')
        }
        axios.get(ip + e.url, {
            params,
        }).then(res => {
            if (res.data.msg == '密码错误2') {
                DelAllCookie()
                e.this.$router.go(0)
                ElMessage({
                    showClose: true,
                    message: '请重新登录~',
                    type: 'warning',
                })
            }
            resolve(res.data)
        }).catch(err => {
            reject(err)
        });

    })
}


async function Post (e) {

    return new Promise((resolve, reject) => {
        var body = {
            ...e.body,
            token: GetCookie('token')
        }

        axios.post(ip + e.url, {
            ...body
        }).then(res => {
            if (res.data.msg == '密码错误2') {
                DelAllCookie()
                e.this.$router.go(0)
                ElMessage({
                    showClose: true,
                    message: '请重新登录~',
                    type: 'warning',
                })
            }
            resolve(res.data)
        }).catch(err => {
            reject(err)
        });

    })
}

export {
    Get,
    Post
}