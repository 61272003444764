<template>

    <HeadNav></HeadNav>
    <div class="hytqs j-f-c-j-c" style="height: 580px;background-color: #f5f7fa;"
        :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">

    </div>
    <FootNav></FootNav>

</template>

<script>
    import HeadNav from './dist/HeadNav.vue'
    import FootNav from './dist/FootNav.vue'
    import { GetCookie } from '../js/cookies.js' //注意路径
    export default {
        components: {
            HeadNav,
            FootNav,
        },
        data () {
            return {
                branch_info: '',
                browserWidth: '',
            }
        },
        async created () {
            this.browserWidth = window.innerWidth
            var While_Value = false
            while (!While_Value) {
                var branch_info = GetCookie('branch_info')
                if (branch_info) {
                    this.branch_info = JSON.parse(branch_info)
                    break
                }
                await this.outTime(500)
            }
        },
        methods: {
            async outTime (time) {
                return new Promise((resolve) => {
                    setTimeout(function () { resolve() }, time)
                })
            }
        }
    }
</script>

<style scoped>

</style>