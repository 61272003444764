import Cookie from 'cookie_js';

function SetCookie (e) {
    for (let i in e) {
        Cookie.set(i, e[i], { expires: 30 });
    }

}
function GetCookie (name) {
    return Cookie.get(name);
}
function DelAllCookie () {
    Cookie.empty()
    return
}
export {
    SetCookie,
    GetCookie,
    DelAllCookie
}