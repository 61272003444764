<template>
  <router-view></router-view>

  <!-- <NavigationHEAD @bind-click="bindclick" msg="Welcome to Your Vue.js App dddd" /> -->
</template>

<script>
// import NavigationHEAD from './components/NavigationHEAD.vue'
export default {
  name: 'App',
  components: {
    // NavigationHEAD
  },
  data() {
    return {
      msg: 123,
      list: [{ id: 1, name: 'zs' }, { id: 2, name: 'dd' }]
    }
  },
  methods: {
    bindclick: function (e) {
      console.log(e)
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      let currentPath = window.location.hash.slice(1)
      if (this.$route.path !== currentPath) {
        this.$router.push(currentPath)
      }
    }, false)
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
</style>
<style>
@import 'css/h.css';
</style>