<template>
    <router-view></router-view>

    <HeadNav></HeadNav>



    <div class="hytqs j-f-c-j-c" style="background-color: #f5f7fa;padding-top: 40px;padding-bottom: 40px;"
        :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">

        <div class="j-f-r">

            <LeftNav></LeftNav>
            <div>
                <div class="j-f-r-c" v-if="user_ifo" style="margin-bottom: 20px;">
                    <div
                        style="background-color: #ffffff;padding: 30px;border-radius: 6px;height: 160px;width: 577.5px;margin-right: 20px;">
                        <div class="j-f-r-c" style="margin-bottom: 15px;">
                            <div style="width: 4px;height: 14px;background-color: #4975e9;margin-right:14px ;" />
                            <div style="color: #1c1f21;font-weight: 700;font-size: 16px;">我的钱包</div>
                        </div>
                        <div class="j-f-r-c" style="margin-top: 50px;">
                            <div style="width: 140px;">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.free_num}}次</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">次数余额</div>
                            </div>

                            <div style="width: 160px;">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.money}}</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">账户余额</div>
                            </div>


                            <div style="width: 160px;">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.referrer_money}}</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">推广佣金(元)</div>
                            </div>

                            <TiXian_button @referrer_money_change="referrer_money_change"></TiXian_button>

                        </div>
                    </div>
                    <div
                        style="background-color: #ffffff;padding: 30px;border-radius: 6px;height: 160px;width: 577.5px;">
                        <div class="j-f-r-c" style="margin-bottom: 15px;">
                            <div style="width: 4px;height: 14px;background-color: #4975e9;margin-right:14px ;" />
                            <div style="color: #1c1f21;font-weight: 700;font-size: 16px;">我的会员</div>
                        </div>
                        <div class="j-f-r-c" style="margin-top: 50px;">

                            <div style="margin-right: 100px;flex-grow: 0;flex-shrink: 0">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.vip}}</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">会员等级</div>
                            </div>

                            <div style="width: 160px;">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.logistics_price}}</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">单号价格</div>
                            </div>

                            <div style="width: 160px;">
                                <div style="color: #1c1f21;font-weight: 800;font-size: 24px;line-height: 24px;">
                                    {{user_ifo.date_free_num}}次</div>
                                <div style="font-size: 14px;opacity: 0.6;margin-top: 16px;">今日免费单号剩余</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    style="background-color: #ffffff;padding: 30px;border-radius: 6px;min-height: 600px;width: 1235px;">

                    <el-tabs model-value="first" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="充值明细" name="first"></el-tab-pane>
                        <el-tab-pane label="提现记录" name="second"></el-tab-pane>
                    </el-tabs>

                    <!-- <div class="j-f-r-c" style="margin-bottom: 15px;">
                        <div style="width: 4px;height: 14px;background-color: #4975e9;margin-right:14px ;" />
                        <div style="color: #1c1f21;font-weight: 700;font-size: 16px;">充值明细</div>
                    </div> -->

                    <div class="j-f-c-j-c-no-a-c" v-if="handleClick_label=='充值明细'">
                        <div class="j-f-r-c"
                            style="background-color: #ffffff;color: #737674;border-bottom: 1px #ebeef5 solid;font-size: 15px;line-height: 50px;position: sticky;top: 80px;z-index: 998;">
                            <div class="j-f-c-j-c" style="width: 300px">订单号(卡密)</div>
                            <div class="j-f-c-j-c" style="width: 140px">充值方式</div>
                            <div class="j-f-c-j-c" style="width: 140px">付款金额</div>
                            <div class="j-f-c-j-c" style="width: 140px">到账金额</div>
                            <div class="j-f-c-j-c" style="width: 140px">到账次数</div>
                            <div class="j-f-c-j-c" style="width: 200px">充值时间</div>
                            <div class="j-f-c-j-c" style="width: 200px">备注</div>
                        </div>
                        <div v-if="load ? false : true" class="j-f-r-c-j-c-c">
                            <img style="width: 39px;height: 39px;margin-top: 200px;margin-bottom: 1000px;"
                                src="../img/load.gif" />
                        </div>
                        <div style="position: relative" v-if="load ? true : false">
                            <div class="j-f-r-c ListItems" v-for="(item, index) in list" :key="index"
                                style="color: #777;font-size: 12px;">

                                <div class="j-f-c-j-c" style="width: 300px">
                                    {{ item.order_id }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.recharge_type }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.price }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.money}}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.num }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 200px">
                                    {{ item.time }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 200px">
                                    -
                                </div>
                            </div>

                        </div>
                        <el-empty v-if="!list.length &&load" description="暂无明细" />
                    </div>

                    <div class="j-f-c-j-c-no-a-c" v-if="handleClick_label=='提现记录'">
                        <div class="j-f-r-c"
                            style="background-color: #ffffff;color: #737674;border-bottom: 1px #ebeef5 solid;font-size: 15px;line-height: 50px;position: sticky;top: 80px;z-index: 998;">
                            <div class="j-f-c-j-c" style="width: 300px">流水号</div>
                            <div class="j-f-c-j-c" style="width: 140px">金额(元)</div>
                            <div class="j-f-c-j-c" style="width: 140px">支付宝姓名</div>
                            <div class="j-f-c-j-c" style="width: 140px">支付宝账号</div>
                            <div class="j-f-c-j-c" style="width: 200px">状态</div>
                            <div class="j-f-c-j-c" style="width: 300px">时间</div>
                        </div>
                        <div v-if="load ? false : true" class="j-f-r-c-j-c-c">
                            <img style="width: 39px;height: 39px;margin-top: 200px;margin-bottom: 1000px;"
                                src="../img/load.gif" />
                        </div>
                        <div style="position: relative" v-if="load ? true : false">
                            <div class="j-f-r-c ListItems" v-for="(item, index) in list" :key="index"
                                style="color: #777;font-size: 12px;">

                                <div class="j-f-c-j-c" style="width: 300px">
                                    {{ item.id }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.referrer_money }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.alipay_name }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 140px">
                                    {{ item.alipay_account }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 200px">
                                    {{ item.state }}
                                </div>
                                <div class="j-f-c-j-c" style="width: 300px">
                                    {{ item.time }}
                                </div>
                            </div>

                        </div>
                        <el-empty v-if="!list.length &&load" description="暂无记录" />
                    </div>

                    <div style="margin-top: 80px;" class="j-f-c-j-c">
                        <el-pagination :current-page="page" @current-change="PaginationChange" background
                            layout="prev, pager, next" :page-count="page_count" />
                    </div>

                </div>
            </div>
        </div>


    </div>
    <FootNav></FootNav>
</template>


<script>
    import TiXian_button from "./dist/TiXian_button.vue";
    import HeadNav from "./dist/HeadNav.vue";
    import FootNav from "./dist/FootNav.vue";
    import LeftNav from "./dist/LeftNav.vue";
    import { Get } from "../js/axios.js";
    export default {
        data () {
            return {
                browserWidth: '',
                handleClick_label: '充值明细',
                user_ifo: false,
                list: [],
                page: 0,
                page_count: 0,
                load: false,
            };
        },
        components: {
            FootNav,
            HeadNav,
            LeftNav,
            TiXian_button
        },
        created () {
            this.browserWidth = window.innerWidth
            this.get_list();
            this.get_user_info()
        },
        computed: {
            pageWidth () {
                return window.innerWidth;
            },
        },
        methods: {
            handleClick (e) {
                this.handleClick_label = e.props.label
                this.list = []
                this.page = 0
                this.page_count = 0
                this.get_list()
            },
            referrer_money_change () {
                this.get_user_info()
            },
            PaginationChange (e) {
                this.page = e - 1
                this.get_list()
            },

            async get_user_info () {
                var res = await Get({
                    url: '/api/get_user_info',
                })
                if (res.code == 200) {
                    this.user_ifo = res.data
                }

            },
            async get_list () {
                var url = ''
                if (this.handleClick_label == '充值明细') {
                    url = '/api/get_recharge_list'
                } else if (this.handleClick_label == '提现记录') {
                    url = '/api/get_tixian_list'
                }

                this.page = this.page + 1;
                this.load = false;
                this.list = [];

                var res = await Get({
                    url,
                    data: {
                        page: this.page - 1,
                    },
                    this: this,
                });
                if (res.code == 200) {
                    this.list = res.data;
                    this.page_count = res.page_count
                    this.load = true;
                }
            },
        },
    };
</script>

<style scoped>
    .demo-tabs>.el-tabs__content {
        padding: 32px;
        color: #6b778c;
        font-size: 32px;
        font-weight: 600;
    }

    .dylsjl {
        color: #999;
    }

    .dylsjl:hover {
        color: #7998ee;
    }

    .buttons {
        width: 100px;
        height: 40px;
        background-color: #4975e9;
        border-radius: 10px;
        cursor: pointer;
    }

    .buttons:hover {
        background-color: #7998ee;
    }

    .inputs {
        width: 250px;
        height: 45px;
        border: 1px #b4bccc solid;

        border-radius: 5px;
    }

    input {
        padding-left: 20px;
        width: 80%;
        height: 45px;
        font-size: 14px;
        color: #606266;
    }

    .input-icon {
        width: 19px;
        height: 19px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .name_right {
        margin-top: 20px;
        font-size: 12px;
        color: #fa525f;
    }

    .name_right text {
        padding: 5px 12px;
    }

    .hsbs {
        position: fixed;
        right: 10px;
        top: 60%;
        z-index: 9999;
        width: 80px;
        height: 80px;
        background-color: #4975e9;
        border-radius: 50%;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
    }

    .hsbs:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .tabitem {
        align-items: center;
        color: #999;
        display: flex;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        margin: 0 8px 13px 8px;
        text-align: center;
        border-radius: 5px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .tabitem:hover {
        color: #4975e9;
        cursor: pointer;
    }

    .tabitemSelect {
        align-items: center;
        color: #fff;
        background-color: #4975e9;
        display: flex;
        font-size: 15px;
        padding: 5px 15px 5px 15px;
        margin: 0 3px 13px 3px;
        text-align: center;
        border-radius: 999px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .tabitemSelect:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .tabitems-lists {
        display: flex;
        justify-content: space-around;
        display: -webkit-flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .khzssc {
        font-size: 13px;
        color: #999;
        text-decoration: underline;
        margin-left: 10px;
        cursor: pointer;
    }

    .khzssc:hover {
        color: #7998ee;
    }

    .colorbq {
        padding: 5px 10px 5px 10px;
        border: 1px #ddd solid;
        color: #999;
        border-radius: 5px;
        font-size: 13px;
        margin-right: 15px;
        cursor: pointer;
    }

    .colorbq:hover {
        border-color: rgb(99, 206, 255);
        color: rgb(17, 153, 238);
    }

    .colorbqSelect {
        border-color: rgb(99, 206, 255);
        background: rgb(230, 249, 255);
        color: rgb(17, 153, 238);
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .windows {
        background-color: #fff;
        border-radius: 10px;
    }

    .ListItems {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 13px;
        border-bottom: 1px #ebeef5 solid;
        background-color: #fff;
    }

    .ListItems:hover {
        background-color: #f5f5f5;
    }

    .phone2:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .textarea1 {
        border: 1px #ededed solid;
        width: 390px;
        height: 200px;
        border-radius: 10px;
        margin-top: 20px;
        padding: 10px;
        font-size: 16px;
    }

    .Maskbutton1 {
        width: 200px;
        height: 50px;
        background-color: #4975e9;
        color: #fff;
        border-radius: 10px;
        margin-top: 50px;
    }

    .Maskbutton1:hover {
        background-color: #7998ee;
        cursor: pointer;
    }

    .Mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        z-index: 1000;
    }

    .items:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .Highlights0 {
        font-size: 12px;
        padding: 2px 5px 2px 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        margin-right: 5px;
    }

    .Highlights1 {
        font-size: 12px;
        padding: 2px 5px 2px 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        margin-right: 5px;
    }

    .list-item-button {
        border-radius: 5px;
        color: #333;
        text-decoration: underline;
    }

    .list-item-button:hover {
        color: #7998ee;
        cursor: pointer;
    }

    .no-vip-Mask-button {
        padding: 10px 20px 10px 20px;
        border-radius: 999px;
        border: 1px #4975e9 solid;
        color: #4975e9;
        box-shadow: 0 0 5px 3px rgba(73, 177, 233, 0.1);
        font-size: 15px;
    }

    .no-vip-Mask-button:hover {
        border: 1px #7998ee solid;
        color: #7998ee;
        cursor: pointer;
    }

    .no-vip-Mask {
        width: 100%;
        height: 333px;
        background-image: linear-gradient(to top,
                rgba(255, 255, 255, 1) 30px,
                rgba(255, 255, 255, 0));
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        bottom: 0;
    }

    td {
        border: 1px solid #f5f5f5;
        text-align: center;
        color: #333;
        font-size: 13px;
    }
</style>