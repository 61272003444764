<template>

    <HeadNav></HeadNav>

    <div class="hytqs j-f-c-j-c" style="background-color: #f5f7fa;"
        :style="browserWidth>1523?'width: 100%;':'width: 1523px;'">
        <div class="j-f-c"
            style="width: 1200px;min-height: 600px;border-radius: 6px;background-color: #fff;padding:20px;">
            <div class="j-f-r-c" style="padding-bottom:26px ;border-bottom: 1px solid var(--el-collapse-border-color);">
                <div style="width: 4px;height:16px;background-color:#4975e9;margin-right: 10px;"></div>
                <div style="font-weight: bold;">常见问题</div>
            </div>

            <div v-for="(item, index) in list" :key="index" @click="on_help_item(index)">
                <div style="padding-top:20px;padding-bottom:20px;border-bottom: 1px #ededed solid;cursor: pointer;">
                    <div class="j-s-b">
                        <div style="color:#666;">{{item.title}}</div>
                        <img :class="{'icon_jt_right':true, 'icon_jt_right_rotated':item.value,'icon_jt_right_rotated2':!item.value}"
                            src="../img/icon_jt_right.png">
                    </div>

                    <div v-if="item.value" style="margin-top:20px;">
                        <div v-for="(items, index2) in item.content_list" :key="index2"
                            style="margin-bottom: 6px;font-size: 14px;">
                            <div v-if="items.type=='text'">{{items.value}}</div>
                            <img v-if="items.type=='img'" :src="items.value"
                                :style="`width: ${items.width?items.width:'1000'}px; height: ${items.height?items.height:'200'}px;`"
                                style="border: 1px #ededed solid;border-radius: 5px;">
                        </div>
                    </div>

                </div>
            </div>

            <!-- <div v-for="(item, index) in list" :key="index" style="font-size: 20px;">
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item :title="item.title" :name="index">
                        <div v-for="(items, index2) in item.content_list" :key="index2">
                            <div v-if="items.type=='text'">{{items.value}}</div>
                            <img v-if="items.type=='img'" :src="items.value"
                                :style="`width: ${items.width?items.width:'1000'}px; height: ${items.height?items.height:'200'}px;`"
                                style="border: 1px #ededed solid;border-radius: 5px;">
                        </div>
                    </el-collapse-item>

                </el-collapse>
            </div> -->

        </div>
    </div>
    <FootNav></FootNav>

</template>

<script>
    import HeadNav from './dist/HeadNav.vue'
    import FootNav from './dist/FootNav.vue'

    export default {
        components: {
            HeadNav,
            FootNav,
        },
        data () {
            return {
                browserWidth: '',
                list: [
                    {
                        title: '如何充值，卡密购买系统如何使用？',
                        content_list: [
                            { type: 'text', value: '1.充值中心' },
                            { type: 'text', value: '2.点击跳转卡密购买系统' },
                            { type: 'text', value: '4.选择要充值的面额' },
                            { type: 'text', value: '5.选择支付宝当面付' },
                            { type: 'text', value: '6.支付成功后获取密钥' },
                            { type: 'text', value: '7.选择卡密提取进行充值' },
                            { type: 'text', value: '充值成功后就可以购买自己所需地域的单号了' },
                            { type: 'text', value: '有问题随时联系客服24小时在线' },
                        ]
                    },
                    // {
                    //     title: '如何充值，卡密系统如何使用？',
                    //     content_list: [
                    //         { type: 'text', value: '1.' },
                    //         { type: 'img', value: 'https://img.zcool.cn/community/01e80f55f8e9c16ac7251df832fc98.jpg@1280w_1l_2o_100sh.jpg', width: '200', height: '100' },
                    //         { type: 'text', value: '2.' }
                    //     ]
                    // },
                    {
                        title: '如何选购快递单号？',
                        content_list: [
                            { type: 'text', value: '1.单号大厅' },
                            { type: 'text', value: '2.选择所用平台（也就是你刷单店铺在的平台，每个平台单号技术不一样）' },
                            { type: 'text', value: '3.选择收货地址' },
                            { type: 'text', value: '4.筛选打单日期（打单日期一定要在你刷单时间之后）' },
                            { type: 'text', value: '5.购买单号' },
                            { type: 'text', value: '6.购买成功后会显示真实单号，填到你对应的平台即可' },
                            { type: 'text', value: '有问题随时联系客服24小时在线' },
                        ]
                    }
                ]
            }
        },
        created () {
            this.browserWidth = window.innerWidth
        },
        methods: {
            on_help_item (i) {
                this.list[i].value = !this.list[i].value
            }
        }
    }
</script>

<style scoped>
    .expanded1 {
        transition: height .3s ease-in-out
    }

    .icon_jt_right {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .icon_jt_right_rotated {
        transform: rotate(90deg);
        /* 如果你想要过渡效果，可以添加 transition 属性 */
        transition: transform 0.3s ease-in-out;
    }

    .icon_jt_right_rotated2 {
        transform: rotate(0deg);
        /* 如果你想要过渡效果，可以添加 transition 属性 */
        transition: transform 0.3s ease-in-out;
    }
</style>