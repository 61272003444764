<template>
    <div class="left_nav">
        <div v-for="(item, index) in nav_list" :key="index" @click="to_page({path:item.path})">
            <div class="j-f-r-c " :class="path==item.path?'nav_item_Select':'nav_item'">
                <img :src="path==item.path?item.Select_icon:item.icon"
                    style="width: 20px;height: 20px;padding-right: 6px;">
                <div>{{item.title}}</div>
            </div>
        </div>
    </div>

</template>

<script>


    export default {
        name: 'LeftNav',
        data () {
            return {
                nav_list: [
                    {
                        title: '我的钱包',
                        path: '/recharge_list',
                        icon: require('../../img/left_icon_recharge.png'),
                        Select_icon: require('../../img/left_icon_recharge_select.png')
                    },
                    {
                        title: '我的订单',
                        path: '/order_list',
                        icon: require('../../img/left_icon_order.png'),
                        Select_icon: require('../../img/left_icon_order_select.png')
                    },

                    {
                        title: '推广赚钱',
                        path: '/pop_money',
                        icon: require('../../img/left_icon_share.png'),
                        Select_icon: require('../../img/left_icon_share_select.png')
                    }
                ]
            }
        },
        components: {

        },
        created () {
            //判断当前页面路径
            this.path = this.$route.fullPath
        },
        methods: {
            to_page (e) {
                this.$router.push({ path: e.path })
            },
        },
    }
</script>

<style scoped>
    .nav_item {
        color: #444444;
        font-weight: bold;
        width: 100%;
        height: 54px;
        cursor: pointer;
        padding-left: 35px;
    }

    .nav_item_Select {
        color: #4975e9;
        font-weight: bold;
        width: 100%;
        height: 54px;
        cursor: pointer;
        padding-left: 35px;
        background-color: #f4f7ff;
    }

    .left_nav {
        width: 200px;
        height: 600px;
        background-color: #fff;
        margin-right: 20px;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        padding-top: 34px;
    }

    .left_nav:before {
        content: "";
        display: inline-block;
        width: 160px;
        height: 6px;
        background: #4975e9;
        border-radius: 4px;
        position: absolute;
        top: -3px;
        left: 50%;
        margin-left: -80px;
    }
</style>