<template>
    <div v-if="type == 'default'">
        <img class="Animation" style="width: 20px;height: 20px;" src="../../img/loadfff.png">
    </div>
    <div v-if="type == 'FullScreen'" class="Mask j-f-c-j-c ">
        <img class="Animation" style="width: 50px;height: 50px;" src="../../img/loadfff.png">
        <div style="color: #fff;margin-top: 33px;">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: 'LoadAnimation',
    data() {

    },
    props: {
        text: {
            type: String,
            default() {
                return ''
            }
        },
        type: {
            type: String,
            default() {
                return 'default'
            }
        }
    },
    methods: {

    },
    created() {

    }
}
</script>

<style scoped>
.Mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.39);
    z-index: 99999;
}

.Animation {
    animation: run 1.5s linear infinite;
}

@keyframes run {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>